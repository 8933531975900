import React from 'react'
import { hasSelectedEmail } from '../domain/transaction'

const getTransactionStatusText = transaction => {
  /* TODO: Add support for exported, with timestamp etc.
  if (!selectedEmail && transaction.exported) {
    return 'Exported without receipts'
  }
  if (transaction.exported) {
    return 'Exported'
  } */

  const selectedEmail = hasSelectedEmail(transaction)

  if (!selectedEmail && transaction.readyForExport) {
    return (
      <span className="transaction-status transaction-status--ignored">
        Ignored
      </span>
    )
  }

  if (transaction.readyForExport) {
    return (
      <span className="transaction-status transaction-status--readyForExport">
        Finished
      </span>
    )
  }

  if (!transaction.searchedForEmails) {
    return (
      <span className="transaction-status transaction-status--notSearchedForEmails">
        Not searched
      </span>
    )
  }

  if (selectedEmail) {
    return (
      <span className="transaction-status transaction-status--chosenReceipt">
        Found receipt
      </span>
    )
  }

  const emailsFound = Object.keys(transaction.emailSearchResults).length

  if (emailsFound > 0) {
    return (
      <span className="transaction-status transaction-status--emailsFound">
        {emailsFound > 1 ? `${emailsFound} emails` : `1 email`}
      </span>
    )
  }
  if (emailsFound === 0) {
    return (
      <span className="transaction-status transaction-status--noEmailFound">
        No emails
      </span>
    )
  }
  return <span className="transaction-status">Unknown status</span>
}

export { getTransactionStatusText }
