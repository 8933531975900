import { Table } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'

import { getTransactionStatusText } from '../../presentation/email'
import { hasSelectedEmail, transactionPropType } from '../../domain/transaction'
import EmailOverview from '../emailOverview'

const mapToDataSourceFormat = (transaction, index, props) => ({
  key: index,
  name: transaction.merchant,
  date: transaction.date,
  sum: `${transaction.sum} ${transaction.currency}`,
  status: getTransactionStatusText(transaction),
  chooseReceipt:
    transaction === props.selectedTransaction
      ? EmailOverviewForTransaction(props)
      : null,
  transaction
})

const sortByEmail = (a, b) => {
  // Finished at the bottom
  if (a.transaction.readyForExport) {
    return 1
  }
  if (b.transaction.readyForExport) {
    return -1
  }
  // Chosen receipts aboce them:
  if (hasSelectedEmail(a.transaction)) {
    return 1
  }
  if (hasSelectedEmail(b.transaction)) {
    return -1
  }
  // Zero matches above them
  if (Object.keys(a.transaction.emailSearchResults).length === 0) {
    return 1
  }
  if (Object.keys(b.transaction.emailSearchResults).length === 0) {
    return -1
  }
  // Perfect matches at the top:
  if (!a.transaction.perfectMatch && b.transaction.perfectMatch) {
    return 1
  }
  if (a.transaction.perfectMatch && !b.transaction.perfectMatch) {
    return -1
  }
  // Everything else sorted by date
  return a.transaction.date.isBefore(b.transaction.date) ? 1 : -1
}

const columns = props => {
  return [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      defaultSortOrder: 'descend',
      className: 'transaction-list-date',

      sorter: (a, b) =>
        a.transaction.date.isBefore(b.transaction.date) ? 1 : -1,
      render: date => date.format('ll')
    },
    {
      title: 'Transactions',
      dataIndex: 'name',
      key: 'name',
      className: 'transaction-list-name',

      colSpan: 2,
      render: name => <strong>{name}</strong>
    },
    {
      title: 'Sum',
      colSpan: 0,
      dataIndex: 'sum',
      key: 'sum',
      className: 'transaction-list-sum'
    },
    {
      title: 'Status',
      colSpan: 2,
      dataIndex: 'status',
      key: 'status',
      className: 'transaction-list-status',
      sorter: sortByEmail,
      onCell: record => {
        const className = () => {
          if (record.transaction.readyForExport) {
            return 'transaction-list-status transaction-list-status--readyForExport'
          }
          if (!record.transaction.searchedForEmails) {
            return 'transaction-list-status transaction-list-status--notSearched'
          }
          if (hasSelectedEmail(record.transaction)) {
            return 'transaction-list-status transaction-list-status--chosenReceipt'
          }
          if (
            record.transaction.emailSearchResults &&
            Object.keys(record.transaction.emailSearchResults).length > 0
          ) {
            return 'transaction-list-status transaction-list-status--emailsFound'
          }
          if (record.transaction.emailSearchResults) {
            return 'transaction-list-status transaction-list-status--noEmailFound'
          }
          return 'transaction-list-status'
        }
        return { className: className() }
      }
    },
    {
      title: 'Choose receipt',
      colSpan: 0,
      dataIndex: 'chooseReceipt',
      key: 'chooseReceipt',
      className: 'transaction-list-chooseReceipt'
    }
  ]
}

const statusClass = transaction => {
  const selectedEmail = hasSelectedEmail(transaction)

  if (!selectedEmail && transaction.readyForExport) {
    return ' transactionRow--ignored'
  }

  if (transaction.readyForExport) {
    return ' transactionRow--readyForExport'
  }

  if (!transaction.searchedForEmails) {
    return ' transactionRow--notSearchedForEmails'
  }

  if (selectedEmail) {
    return ' transactionRow--chosenReceipt'
  }

  const emailsFound = Object.keys(transaction.emailSearchResults).length

  if (emailsFound > 0) {
    return ' transactionRow--emailsFound'
  }
  if (emailsFound === 0) {
    return ' transactionRow--noEmailFound'
  }
  return ''
}

const EmailOverviewForTransaction = props => {
  const {
    selectedTransaction,
    emails,
    onFreeTextEmailSearch,
    onSelectEmail,
    onMarkEmail,
    selectNextTransaction,
    markTransactionAsReadyForExport,
    searchForTransactionEmails,
    searchingForEmail
  } = props
  return (
    <EmailOverview
      selectedTransaction={selectedTransaction}
      emails={emails}
      onFreeTextEmailSearch={onFreeTextEmailSearch}
      onSelectEmail={onSelectEmail}
      onMarkEmail={onMarkEmail}
      selectNextTransaction={selectNextTransaction}
      markTransactionAsReadyForExport={markTransactionAsReadyForExport}
      searchForTransactionEmails={searchForTransactionEmails}
      searchingForEmail={searchingForEmail}
    />
  )
}

const TransactionList = props => {
  const {
    transactions,
    selectedTransaction,
    onSelectTransaction,
    loadingTransactions
  } = props
  const dataSource = transactions.map((transaction, index) =>
    mapToDataSourceFormat(transaction, index, props)
  )
  return dataSource.length > 0 ? (
    <Table
      id="transactionListTable"
      showHeader={true}
      columns={columns(props)}
      dataSource={dataSource}
      pagination={false}
      loading={loadingTransactions}
      onRow={item => ({
        id:
          'transactionListTable__row--id-' +
          item.transaction.id.replace(/[^A-Za-z0-9]/g, ''), // Ugly hack, should use refs
        onClick: event => {
          // if clicking outside .emailOverview, select or unselect the transaction:
          if (!event.target.closest('.emailOverview')) {
            onSelectTransaction(
              item.transaction === selectedTransaction
                ? false
                : item.transaction
            )
          }
        }
      })}
      rowClassName={item => {
        let className = 'transactionListTable__row'
        if (item.transaction === selectedTransaction)
          className += ' active-transaction-row'
        className += statusClass(item.transaction)
        return className
      }}
    />
  ) : null
}

TransactionList.propTypes = {
  transactions: PropTypes.arrayOf(transactionPropType),
  onSelectTransaction: PropTypes.func,
  selectedTransaction: transactionPropType
}

export default TransactionList
