import moment from './moment'

import {
  standardizeCurrencyFormat,
  isReservedTransaction,
  isBankCharge,
  isForeignTransaction,
  isDomesticTransaction,
  isGiroTransaction,
  norwegianCurrencyKey,
  dnbDateFormat
} from '../domain/dnb'
import { tweakTransactionData } from '../domain/transaction'

const findMatches = (regex, replacementString, parsedJSON) => {
  try {
    const matches = parsedJSON.textMore.replace(regex, replacementString)
    return JSON.parse(matches)
  } catch (e) {
    return
  }
}

const mapDomesticTransaction = parsedJSON => {
  const firstOutputRegex = new RegExp(/^\d+ ([Nok]+) (\d*),(\d+) ((\w)+)(.*)/g)
  const firstOutputReplacement = '{"merchant": "$4", "description": "$4$6"}'
  const firstOutputMatches = findMatches(
    firstOutputRegex,
    firstOutputReplacement,
    parsedJSON
  )

  const lastOutputRegex = new RegExp(/^\d+ (\w+)(.+) ([Nok]+)/g)
  const filterLastReplacement = '{"merchant": "$1", "description": "$1$2"}'
  const lastOutputMatches = findMatches(
    lastOutputRegex,
    filterLastReplacement,
    parsedJSON
  )

  if (!firstOutputMatches && !lastOutputMatches) {
    return
  }

  return { ...firstOutputMatches, ...lastOutputMatches }
}

const mapForeignTransaction = parsedJSON => {
  const currencyRegex = new RegExp(
    /^\d+ (\w+) (\d*),(\d+) (\w+)(.+) ([Valutakurs:]+.+)/g
  )
  const currencyReplacement =
    '{"currency": "$1", "sum": "$2.$3", "merchant": "$4", "description": "$4$5"}'
  const matches = findMatches(currencyRegex, currencyReplacement, parsedJSON)
  if (!matches) {
    return
  }

  return {
    currency: matches.currency.toUpperCase(),
    sum: matches.sum,
    merchant: matches.merchant,
    description: matches.description
  }
}

const mapTransactionFromDNB = (parsedJSON = {}) => {
  if (isReservedTransaction(parsedJSON) || isBankCharge(parsedJSON)) {
    return
  }
  const sum = parsedJSON.in
    ? standardizeCurrencyFormat(parsedJSON.in)
    : standardizeCurrencyFormat(parsedJSON.out)

  if (!sum) {
    return
  }

  const timezone = 'Europe/Oslo' // DNB files are in norway

  const defaultTransactionData = {
    sum,
    sumInCardCurrency: sum,
    dateTime: {
      utc: moment.tz(parsedJSON.date, dnbDateFormat, timezone).format('x'),
      timezone: timezone
    },
    currency: norwegianCurrencyKey,
    cardCurrency: norwegianCurrencyKey,
    originalData: parsedJSON,
    merchant: parsedJSON.textMore
  }

  let transaction = false
  if (isForeignTransaction(parsedJSON)) {
    const result = mapForeignTransaction(parsedJSON)
    if (result) transaction = { ...defaultTransactionData, ...result }
  } else if (isDomesticTransaction(parsedJSON)) {
    const result = mapDomesticTransaction(parsedJSON)
    if (result) transaction = { ...defaultTransactionData, ...result }
  } else if (isGiroTransaction(parsedJSON)) {
    transaction = { ...defaultTransactionData, description: '' }
  } else if (!parsedJSON.out && !!parsedJSON.in) {
    transaction = {
      ...defaultTransactionData,
      description: '(Income)'
    }
  } else if (!!parsedJSON.out && !parsedJSON.in) {
    transaction = {
      ...defaultTransactionData,
      description: '(Expense)'
    }
  }
  return transaction ? tweakTransactionData(transaction) : undefined
}

export default mapTransactionFromDNB
