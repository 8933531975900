import request from 'request-promise-native'
import config from '../../config'
import moment from '../../util/moment'
import generateId from '../../util/generateId'

import { message } from 'antd'

export const parsePlaidAccounts = (
  plaidAccountsResponse,
  plaid_access_token
) => {
  // Takes result of /bank/plaid/accounts, returns an array of account objects
  // Input format at https://plaid.com/docs/#accounts

  if (
    !plaidAccountsResponse ||
    !plaidAccountsResponse.accounts ||
    !plaidAccountsResponse.accounts.length > 0
  )
    return null

  const institution_id = plaidAccountsResponse.item.institution_id

  // Output format:
  const accounts = plaidAccountsResponse.accounts.map(account => ({
    name: account.name
      ? account.name
      : account.official_name
      ? account.official_name
      : 'Account through Plaid',
    number: account.mask,
    api: {
      id: 'plaid',
      account_id: account.account_id
        ? account.account_id
        : account.id
        ? account.id
        : null,
      auth_token: plaid_access_token,
      institution_id: institution_id
    },
    id: `plaid-${generateId([
      account.mask,
      account.name,
      account.official_name
    ])}` // There is an account.account_id, but Plaid gives a new one for each time you login. Thus, this sucky situation
  }))

  return accounts
}

export const fetchPlaidAccounts = async plaid_access_token => {
  if (!plaid_access_token) message.error('No access token given')
  // Fetches a list of Plaid accounts and returns as RR accounts
  try {
    const plaidAccountsResponse = await request(
      `${config.apiUri}/api/bank/plaid/accounts`,
      {
        method: 'POST',
        json: true,
        body: { access_token: plaid_access_token }
      }
    )
    if (
      !plaidAccountsResponse.accounts ||
      plaidAccountsResponse.accounts.status_code !== 200
    )
      throw plaidAccountsResponse
    return parsePlaidAccounts(
      plaidAccountsResponse.accounts,
      plaid_access_token
    )
  } catch (error) {
    message.error("Couldn't fetch acccounts")
    console.error(error)
    return false
  }
}

export const parsePlaidTransactions = (
  plaidTransactionsResponse,
  accountId = false
) => {
  // Takes result of /bank/plaid/transactions, returns an array of transaction objects
  // Input format at https://plaid.com/docs/#transactions

  if (
    !plaidTransactionsResponse ||
    !plaidTransactionsResponse.transactions ||
    !plaidTransactionsResponse.transactions.length > 0
  )
    return null

  // For some reason, Plaid doesn't filter out the transactions from accountId set in options.
  // Might only be a sandbox thing, but doing it here again anyway:
  // Filter out transactions that do not match accountId
  const transactionsToParse = plaidTransactionsResponse.transactions.filter(
    transaction => transaction.account_id === accountId
  )

  // Output format:
  const transactions = transactionsToParse.map(transaction => {
    const timezone = 'utc' // Plaid has no timezone support.
    // We can try to guess it, but setting it to +12:00 should keep it on the same date for all time zones.
    return {
      merchant: transaction.name,
      sum: `${transaction.amount}`,
      sumInCardCurrency: `${transaction.amount}`,
      currency: transaction.iso_currency_code,
      cardCurrency: transaction.iso_currency_code,
      dateTime: {
        utc: moment
          .tz(transaction.date, 'YYYY-MM-DD', timezone)
          .add('12', 'hours')
          .format('x'),
        timezone: timezone
      },
      description: '',
      originalData: transaction
    }
  })

  return transactions
}

export const fetchPlaidTransactions = async (
  plaid_access_token,
  plaidAccountId,
  startDate, // moment, or ignored
  endDate // moment, or ignored
) => {
  if (!plaid_access_token) message.error('No access token given')
  if (!plaidAccountId) message.error('No account ID given')
  // Fetches a list of Plaid transactions for one plaid account and returns as RR transactions
  const options = {
    count: 500,
    offset: 0
  }
  const fetchTransactions = async (count, offset) => {
    return request(`${config.apiUri}/api/bank/plaid/transactions`, {
      method: 'POST',
      json: true,
      body: {
        access_token: plaid_access_token,
        start_date: moment.isMoment(startDate)
          ? startDate.format('YYYY-MM-DD')
          : moment()
              .subtract('30', 'days')
              .format('YYYY-MM-DD'),
        end_date: moment.isMoment(endDate)
          ? endDate.format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD'),
        account_ids: [plaidAccountId],
        count,
        offset
      }
    })
  }
  try {
    const plaidTransactionsResponse = await fetchTransactions(
      options.count,
      options.offset
    )
    if (
      !plaidTransactionsResponse.transactions ||
      plaidTransactionsResponse.transactions.status_code !== 200
    ) {
      throw plaidTransactionsResponse
    }
    const total_transactions =
      plaidTransactionsResponse.transactions.total_transactions

    // We have a list of transactions! Is it incomplete?
    if (total_transactions > options.count) {
      // How many rounds do we have to go to fetch all?
      const rounds = Math.ceil(
        (total_transactions - options.count) / options.count
      )
      // Lets fetch them all:
      for (let i = 0; i < rounds; i++) {
        options.offset = options.count * (i + 1)
        const offsetTransactionResponse = await fetchTransactions(
          options.count,
          options.offset
        )
        plaidTransactionsResponse.transactions.transactions.push(
          ...offsetTransactionResponse.transactions.transactions
        )
      }
    }

    return parsePlaidTransactions(
      plaidTransactionsResponse.transactions,
      plaidAccountId
    )
  } catch (error) {
    if (!error || !error.error || !error.error.error_code) {
      console.error(error)
      message.error("Couldn't fetch transactions, but no error code. ")
      return false
    }
    const error_code = error.error.error_code
    // Probably this triggers when the login isn't working...
    switch (error_code) {
      case 'INVALID_ACCESS_TOKEN':
      case 'ITEM_LOGIN_REQUIRED':
      case 'INVALID_UPDATED_USERNAME':
      case 'INVALID_CREDENTIALS':
      case 'INVALID_MFA':
        // These should trigger new login, then attempt this again:
        const public_token = await fetchPlaidPublicToken(plaid_access_token)
        return {
          error: true,
          api: 'plaid',
          trigger_new_login: true,
          options: {
            public_token
          }
        }

      case 'INSTITUTION_NO_LONGER_SUPPORTED':
        // Bank no longer supported :( Not sure how to handle this.
        message.error('This bank is lo longer supported by Plaid :( ')
        return { error: true, api: 'plaid' }

      case 'INSTITUTION_DOWN':
      case 'INSTITUTION_NOT_RESPONDING':
      case 'INSTITUTION_NOT_AVAILABLE':
        message.error('Bank is not responding, try again in a little while :( ')
        // Bank or Plaid is down, wait a little and try again
        return { error: true, api: 'plaid' }
      default:
        message.error(`Bank error: ${error.error_code}. Sorry!`)
        return { error: true, api: 'plaid' }
    }
  }
}

export const fetchPlaidPublicToken = async access_token => {
  try {
    const plaidAuthResponse = await request(
      `${config.apiUri}/api/bank/plaid/get_public_token`,
      {
        method: 'POST',
        json: true,
        body: {
          access_token
        }
      }
    )
    if (!plaidAuthResponse.public_token) throw plaidAuthResponse
    return plaidAuthResponse.public_token
  } catch (error) {
    message.error("Couldn't fetch public token")
    console.error(error)
    return false
  }
}

export const fetchPlaidAccessToken = async public_token => {
  try {
    const plaidAuthResponse = await request(
      `${config.apiUri}/api/bank/plaid/get_access_token`,
      {
        method: 'POST',
        json: true,
        body: {
          public_token: public_token
        }
      }
    )
    if (!plaidAuthResponse.access_token) throw plaidAuthResponse

    // TODO : ENCRYPT HERE before passing on
    return plaidAuthResponse.access_token
  } catch (error) {
    message.error("Couldn't fetch access token")
    console.error(error)
    return false
  }
}
