import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

import EmailListItem from './EmailListItem'
import { Layout } from 'antd'

const { Content } = Layout

class EmailList extends PureComponent {
  render() {
    const messages = Object.values(this.props.messages) || []
    if (messages.length === 0) return null

    return (
      <Content className="emailList">
        {messages.map(message => (
          <EmailListItem
            key={message.id}
            message={message}
            onSelectEmail={this.props.onSelectEmail}
            onMarkEmail={this.props.onMarkEmail}
            readyForExport={this.props.readyForExport}
            className="emailListItem"
            finishAction={this.props.finishAction}
            finishKey={this.props.finishKey}
          />
        ))}
      </Content>
    )
  }
}

EmailList.propTypes = {
  messages: PropTypes.object,
  onMarkEmail: PropTypes.func,
  onSelectEmail: PropTypes.func
}

export default EmailList
