import { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { initializeGapi } from '../google/gapi'

class GoogleApiLoader extends PureComponent {
  componentDidMount() {
    const script = document.createElement('script')
    script.src = 'https://apis.google.com/js/api.js'
    script.onload = () => {
      window.gapi.load('client', async () => {
        try {
          await initializeGapi()
          this.props.onGoogleApiLoaded()
        } catch (e) {
          console.log(e)
        }
      })
    }
    document.body.appendChild(script)
  }

  render(props) {
    return null
  }
}

GoogleApiLoader.propTypes = {
  onGoogleApiLoaded: PropTypes.func
}

export default GoogleApiLoader
