import { Button, Layout, Card, Icon } from 'antd'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import moment from '../../../util/moment'
import { downloadAttachmentsDirectly } from '../../../domain/gmail'

const { Content } = Layout

class EmailListItem extends PureComponent {
  constructor() {
    super()
    this.state = {
      expanded: false,
      iframeHeight: null
    }
  }

  expand = () => {
    this.props.onSelectEmail(this.props.message)
    this.setState({ expanded: true })
  }

  close = () => {
    this.setState({ expanded: false })
  }

  getIframeRef = iframe => {
    if (!iframe) {
      return
    }
    iframe.contentWindow.document.open()
    iframe.contentWindow.document.write(this.props.message.html)
    iframe.contentWindow.document.close()
    const iframeHeight =
      iframe.contentWindow.document.body.offsetHeight + 60 + 'px'
    this.setState({ iframeHeight })
  }

  renderExpandedRow = () => {
    const { message } = this.props
    return (
      <Layout className="email__preview">
        {message.html && (
          <iframe
            title="Email preview"
            className="email__preview__iframe"
            ref={this.getIframeRef}
            height={this.state.iframeHeight}
          />
        )}
        {!message.html && message.text && <pre>{message.text}</pre>}
      </Layout>
    )
  }

  renderAttachments = () => {
    const { message } = this.props
    if (
      message.loadingAttachments ||
      !message.attachments ||
      message.attachments.length === 0
    ) {
      return null
    }
    return (
      <Button
        onClick={() => downloadAttachmentsDirectly(message)}
        className="email__download-attachments-button"
      >
        Last ned vedlegg ({message.attachments.length} stk)
      </Button>
    )
  }

  emailActions = () => {
    const {
      onMarkEmail,
      message,
      readyForExport,
      finishAction,
      finishKey
    } = this.props
    const { expanded } = this.state

    const actions = []

    if (!readyForExport && message.selected) {
      actions.push({
        className: 'email__action email__action--main',
        onClick: finishAction,
        icon: null,
        text: `Finished with receipt [${finishKey}]`
      })
    }

    actions.push({
      className: 'email__action',
      onClick: event => {
        event.stopPropagation()
        onMarkEmail(message, true)
      },
      icon: message.selected ? 'cross' : 'plus',
      text: message.selected ? 'Remove from receipt' : 'Add to receipt'
    })

    actions.push({
      className: 'email__action',
      onClick: event => {
        event.stopPropagation()
        expanded ? this.close() : this.expand()
      },
      icon: expanded ? 'up' : 'down',
      text: expanded ? 'Close email' : 'Show email'
    })

    return (
      <nav className="email__actionList">
        <ul>
          {actions.map((action, index) => (
            <li key={`email__actionList-${index}`}>
              <Button
                key={`email__actionList-${index}-${action.icon}`}
                onClick={action.onClick}
                className={action.className}
                icon={action.icon}
              >
                {action.text}
              </Button>
            </li>
          ))}
        </ul>
      </nav>
    )
  }

  render() {
    const { message, readyForExport } = this.props
    const { expanded } = this.state
    return (
      <Card
        className={[
          message.selected && readyForExport
            ? 'email--selected'
            : message.selected && !readyForExport
            ? 'email--suggested'
            : 'email',
          expanded ? 'email--open' : 'email--closed'
        ]}
      >
        <Content>
          <h3 className="email__header">
            <span className="email__header--subject">{message.subject}</span>
            {message.score ? (
              <span className="email__header--score">({message.score})</span>
            ) : null}
            {message.perfectMatch ? (
              <span className="email__header--score">
                (<Icon type="star" title="Good match" />)
              </span>
            ) : null}
          </h3>
          <span className="email__meta">
            {moment(message.datetime, 'x').format('ll')} fra {message.from}
          </span>
          {this.emailActions()}
          <div className="email__attachments">
            {expanded && this.renderAttachments()}
            {expanded && this.renderExpandedRow()}
          </div>
        </Content>
      </Card>
    )
  }
}

EmailListItem.propTypes = {
  message: PropTypes.object,
  onSelectEmail: PropTypes.func,
  onMarkEmail: PropTypes.func
}

export default EmailListItem
