import { parsePayloadHeaders, parsePayloadParts } from './gmailParser'
import generateId from './generateId'

export async function rawGmailSearch(queryString) {
  const { result } = await window.gapi.client.gmail.users.messages.list({
    userId: 'me',
    q: queryString
  })
  return result.messages || []
}

export async function searchForEmail(queryString) {
  const emailAccount = await getEmailAccount()
  const messages = await rawGmailSearch(queryString)
  const messageDetailPromises = messages.map(message =>
    getMessageDetails(message, emailAccount)
  )
  const emailSearchResults = await Promise.all(messageDetailPromises)
  return emailSearchResults
}

function emailId(email, emailAccount) {
  return generateId(
    [],
    email.id,
    `-emailAccountId${emailAccountId(emailAccount)}`
  )
}

function emailAccountId(emailAccount) {
  return generateId([emailAccount], `${emailAccount}-`)
}

async function getEmailAccount(userId = 'me') {
  const { result } = await window.gapi.client.gmail.users.getProfile({
    userId
  })
  return result.emailAddress
}

export async function getMessageDetails(message, emailAccount) {
  if (!emailAccount) {
    emailAccount = await getEmailAccount()
  }
  const { result } = await window.gapi.client.gmail.users.messages.get({
    userId: 'me',
    id: message.id
  })
  const id = emailId(result, emailAccount)
  const accountId = emailAccountId(emailAccount)
  const headers = parsePayloadHeaders(result.payload)
  const parts = parsePayloadParts(result.payload)
  const attachments = parts.filter(part => !!part.attachmentId)
  const htmlPart = parts.find(part => !!part.html)
  const textPart = parts.find(part => !!part.text)
  return {
    id: id,
    accountId: accountId,
    snippet: result.snippet,
    datetime: result.internalDate,
    ...headers,
    attachments,
    html: htmlPart ? htmlPart.content : undefined,
    text: textPart ? textPart.content : undefined,
    score: message.score ? message.score : undefined
  }
}

export async function getAttachmentData(messageId, attachmentId) {
  return await window.gapi.client.gmail.users.messages.attachments.get({
    id: attachmentId,
    messageId: messageId,
    userId: 'me'
  })
}
