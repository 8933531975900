import {
  fetchPlaidAccounts,
  fetchPlaidTransactions,
  fetchPlaidAccessToken,
  parsePlaidAccounts
} from './banks/plaid'
import moment, { combineDateRanges, rangeIsCovered } from '../util/moment'
import database from '../database'
import { message } from 'antd'

// //
// API methods for syncing from banks

export const fetchBankAuthTokenThroughApi = async (bankApi, options = {}) => {
  switch (bankApi) {
    case 'plaid':
      const plaid_access_token = await fetchPlaidAccessToken(
        options.public_token
      )
      return plaid_access_token
    default:
      message.error(
        'Unknown bank given for authentication, something is wrong :('
      )
      return null
  }
}

export const fetchTransactionsThroughApi = async (account, options = {}) => {
  // First decide what API to use
  if (!account) {
    message.error('Unkown Account, something is off :(')
    return null
  }
  const apiUsed = account.api ? account.api.id : false

  if (!apiUsed) {
    // This account doesn't fetch transactions this way
    return
  }

  // Then set startDate and endDate to be equal to current range, if nothing else is specified:
  options.startDate = options.startDate ? options.startDate : account.startDate
  options.endDate = options.endDate ? options.endDate : account.endDate

  // This is an inline function to do it async, not sure if it's the best solution.
  const getTransactions = async () => {
    switch (apiUsed) {
      case 'plaid':
        const plaidAccountId = account.api.account_id
        const plaid_access_token = account.api.auth_token
        return await fetchPlaidTransactions(
          plaid_access_token, // TODO: Should check if still valid. Probably isn't. If it isn't valid, we need to check for error.
          plaidAccountId,
          options.startDate ? options.startDate : null,
          options.endDate ? options.endDate : null
        )
      default:
        message.error('Unknown API, something is wrong :(')
        return []
    }
  }

  const transactions = await getTransactions()
  // Check for errors:
  if (transactions && transactions.error) {
    throw transactions
  }

  logImportedDates(account, options.startDate, options.endDate) // log the import range
  if (!transactions || !transactions.length > 0) {
    message.info('No transactions found for these dates')
    return
  }
  // TODO: logImportedDates does not respect pagination, should check if transactionCount > options.count, and then get the "end date" from the last tx instead.
  return database.transaction.createAll(transactions, account.id)
}

export const fetchAccountsThroughApi = async (api = 'plaid', data = null) => {
  if (api === 'plaid') {
    let accounts = []
    if (!data || !data.plaid_access_token) return
    if (data.accounts) {
      accounts = parsePlaidAccounts(
        {
          accounts: data.accounts,
          item: {
            institution_id: data.institution
              ? data.institution.institution_id
              : null
          }
        },
        data.plaid_access_token
      )
    } else {
      // No accounts? Let's fetch all:
      accounts = await fetchPlaidAccounts(data.plaid_access_token)
    }
    if (!accounts || !accounts.length > 0) {
      message.error('No accounts fetched')
      return
    }
    accounts.forEach(account => {
      database.account.create({ ...account, bankApiNeedsLogin: null })
    })
  }
}

const getAccountImportedDateRanges = account => {
  const accountRanges = account.importedRanges ? account.importedRanges : []
  // Convert to moment ranges:
  return accountRanges.map(range =>
    moment.range(moment(range.startDate, 'x'), moment(range.endDate, 'x'))
  )
}

const logImportedDates = async (account, startDate, endDate) => {
  if (!account || !startDate || !endDate) {
    message.error('Missing one or more variables')
    return
  }
  if (!moment.isMoment(startDate) || !moment.isMoment(endDate)) {
    message.error('Not a moment')
    return
  }
  const accountRanges = getAccountImportedDateRanges(account)
  const newRange = moment.range(startDate, endDate)

  const combinedRanges = combineDateRanges(accountRanges.concat(newRange))

  const unixRanges = combinedRanges.map(range => ({
    startDate: range.start.format('x'),
    endDate: range.end.format('x')
  }))
  database.account.update(account.id, { importedRanges: unixRanges })
}

export const alreadyFetchedTransactionsForDates = (
  account,
  startDate,
  endDate
) => {
  // Checks if the given account has fetched transactions for these dates already
  const accountRanges = getAccountImportedDateRanges(account)
  if (!accountRanges) return false
  const newRange = moment.range(startDate, endDate)
  const isCovered = rangeIsCovered(newRange, accountRanges)
  return isCovered
}
