import PropTypes from 'prop-types'

import { emailSearchResultPropType } from './emailSearchResult'

const transactionPropType = PropTypes.shape({
  emailSearchResult: PropTypes.arrayOf(emailSearchResultPropType),
  merchant: PropTypes.string,
  sum: PropTypes.string,
  sumInCardCurrency: PropTypes.string,
  currency: PropTypes.string,
  cardCurrency: PropTypes.string,
  selected: PropTypes.bool,
  date: PropTypes.object,
  description: PropTypes.string,
  originalData: PropTypes.object,
  exported: PropTypes.object,
  loadingAttachments: PropTypes.bool,
  perfectMatch: PropTypes.bool,
  readyForExport: PropTypes.bool,
  searchedForEmails: PropTypes.string
})

function tweakTransactionData(transaction) {
  // Manual tweaks fixing non-friendly tx data

  /*
      This fixes a bug where, if there is no real match for description,
      it would just cut off the last letter of the merchant name and think that
      was it. So if "description" is just one letter, we assume that's what's
      happened and stitch the patient back together.
    */
  if (transaction.description.length === 1) {
    transaction.merchant = `${transaction.merchant}${transaction.description}`
    transaction.description = ''
  } else if (
    transaction.description.replace(transaction.merchant, '').length === 1
  ) {
    transaction.merchant = transaction.description
    transaction.description = ''
  }

  // Fixes for the merchant names:
  switch (transaction.merchant.toUpperCase()) {
    case 'WWW': // Merchant name is URL. Assume description is the full url, and we want the second word of description instead
      transaction.merchant = transaction.description
        .split('.')
        .join(' ')
        .split(' ')[1] // Check if the description is just the url, if so - remove the rest.
      break
    case 'FACEBK':
      transaction.merchant = 'Facebook'
      break
    case 'AMZN':
      transaction.merchant = 'Amazon'
      break
    default:
      break
  }

  return transaction
}

function trimTransactionData(transaction) {
  return {
    emailSearchResults: Object.values(transaction.emailSearchResults).filter(
      emailSearchResult => emailSearchResult.selected
    ),
    merchant: transaction.merchant,
    sum: transaction.sum,
    sumInCardCurrency: transaction.sumInCardCurrency,
    currency: transaction.currency,
    cardCurrency: transaction.cardCurrency,
    selected: transaction.selected,
    date: transaction.date,
    dateTime: transaction.dateTime,
    description: transaction.description,
    id: transaction.id
  }
}

function updateTransaction(
  transactions = [],
  selectedTransactionIndex = -1,
  newTransaction
) {
  return transactions.map((transaction, index) =>
    index === selectedTransactionIndex
      ? { ...transaction, ...newTransaction }
      : transaction
  )
}

function hasSelectedEmail(transaction) {
  if (
    !transaction ||
    !transaction.emailSearchResults ||
    Object.keys(transaction.emailSearchResults).length === 0
  )
    return false
  return !!Object.values(transaction.emailSearchResults).find(
    emailSearchResult => emailSearchResult.selected
  )
}

function hasAllSearchedForEmails(transactions) {
  if (!transactions || Object.keys(transactions).length === 0) return true // All searched if no transactions
  return !Object.values(transactions).find(
    transaction => !transaction.searchedForEmails && !transaction.readyForExport
  )
}
function hasAnySuggestedEmails(transactions) {
  if (!transactions || Object.keys(transactions).length === 0) return false
  return !!Object.values(transactions).find(
    transaction => hasSelectedEmail(transaction) && !transaction.readyForExport
  )
}

export {
  transactionPropType,
  updateTransaction,
  trimTransactionData,
  tweakTransactionData,
  hasSelectedEmail,
  hasAllSearchedForEmails,
  hasAnySuggestedEmails
}
