import { firebaseLogin, firebaseLogout } from '../google/firebase'
import { gapiLogin, gapiLogout } from '../google/gapi'

export async function login() {
  const idToken = await gapiLogin()
  const user = firebaseLogin(idToken)
  return user
}

export async function logout() {
  await gapiLogout()
  await firebaseLogout()
}

export async function restoreSession() {
  const user = await window.gapi.auth2.getAuthInstance().currentUser.get()
  const idToken = user.getAuthResponse().id_token
  return await firebaseLogin(idToken)
}
