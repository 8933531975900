const initialState = {
  transactions: [],
  selectedTransactionIndex: -1,
  loadingTransactions: false,
  isLoggedIn: false,
  googleApiLoaded: false,
  searchingForEmail: false,
  uploading: false,
  fileName: '',
  uuid: undefined,
  accounts: [],
  selectedAccount: false,
  importingNewTransactions: false,
  emails: {}
}

export { initialState }
