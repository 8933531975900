import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import KeyboardEventHandler from 'react-keyboard-event-handler'

import { transactionPropType, hasSelectedEmail } from '../../domain/transaction'
import EmailList from './emailList'
import EmailListHeader from './emailListHeader'
import TransactionNavigation from './transactionNavigation'
// import FreeTextSearch from './FreeTextSearch'
import HandleNonEmailReceipts from './HandleNonEmailReceipts'

import './styles.css'

class EmailOverview extends PureComponent {
  constructor(props) {
    super(props)
    this.emailOverview = React.createRef()
    this.state = {
      emailOverviewOverflowing: false
    }
  }

  componentDidMount = () => {
    if (this.emailOverview) {
      const emailOverview = this.emailOverview.current
      if (emailOverview.clientHeight < emailOverview.scrollHeight) {
        this.setState({ emailOverviewOverflowing: true })
      } else if (this.state.emailOverviewOverflowing) {
        this.setState({ emailOverviewOverflowing: false })
      }
    }
  }

  render() {
    const {
      selectedTransaction,
      onSelectEmail,
      onMarkEmail,
      // onFreeTextEmailSearch,
      searchForTransactionEmails,
      searchingForEmail,
      selectNextTransaction,
      emails,
      markTransactionAsReadyForExport
    } = this.props
    const { emailOverviewOverflowing } = this.state

    if (!selectedTransaction) {
      return null
    }

    const transactionEmails = selectedTransaction.emailSearchResults
    const messages = {}
    // Get combined list of messages, fetching data if it exists from loaded emails:
    if (selectedTransaction && transactionEmails) {
      for (const key in transactionEmails) {
        if (!transactionEmails.hasOwnProperty(key)) continue
        messages[key] = emails[key]
          ? { ...transactionEmails[key], ...emails[key].data }
          : transactionEmails[key]
      }
    }

    const emailSelected = hasSelectedEmail(selectedTransaction)

    // Keyboard bindings

    const readyForExport = selectedTransaction.readyForExport

    const cancelKey = 'q'
    const cancelAction = () => {
      markTransactionAsReadyForExport(selectedTransaction.id, false)
    }
    const finishKey = 'f'
    const finishAction = () => {
      markTransactionAsReadyForExport(selectedTransaction.id)
    }
    const ignoreKey = 'e'
    const ignoreAction = () => {
      markTransactionAsReadyForExport(selectedTransaction.id)
    }
    const markEmailKeys = ['1', '2', '3', '4']
    const markEmailAction = emailToMark => {
      // Get email
      const email = Object.values(messages)[emailToMark - 1]
      if (email) {
        onMarkEmail(email, true)
      }
    }

    const handleKeyShortcuts = (key, event) => {
      // Ignore if in text or textarea type inputs:
      if (
        (event.target.type === 'text' && event.target.tagName === 'INPUT') ||
        event.target.tagName === 'TEXTAREA'
      ) {
        return
      }
      // Set emailToMark key:
      if (markEmailKeys.indexOf(key) > -1) {
        markEmailAction(key)
        return
      }
      // Handle other keys:
      switch (key) {
        case cancelKey:
          cancelAction()
          break
        case finishKey:
          finishAction()
          break
        case ignoreKey:
          ignoreAction()
          break
        default:
      }
    }

    const keyArray = []
    if (readyForExport) keyArray.push(cancelKey)
    keyArray.push(emailSelected ? finishKey : ignoreKey)
    keyArray.push(...markEmailKeys)
    // End keyboard bindings

    return (
      <div
        className={
          emailOverviewOverflowing
            ? 'emailOverview emailOverview--overflowing'
            : 'emailOverview'
        }
        ref={this.emailOverview}
      >
        <KeyboardEventHandler
          handleKeys={keyArray}
          onKeyEvent={handleKeyShortcuts}
          handleFocusableElements={true}
        />

        <div className="email__wrapper">
          <EmailListHeader
            transaction={selectedTransaction}
            searchForTransactionEmails={searchForTransactionEmails}
            searchingForEmail={searchingForEmail}
          />
          {!emailSelected && readyForExport ? null : (
            <EmailList
              messages={messages}
              onSelectEmail={onSelectEmail}
              onMarkEmail={onMarkEmail}
              readyForExport={readyForExport}
              finishAction={finishAction}
              finishKey={finishKey}
            />
          )}
          {/* 
          
          // FreetextSearch is a work in progress, and needs to improve before hitting master

          {!emailSelected && readyForExport ? null : (
            <FreeTextSearch
              key={selectedTransaction.id}
              className="FreeTextSearch"
              defaultValue={selectedTransaction.emailSearchQuery}
              onSearchForEmail={onFreeTextEmailSearch}
              hasEmailSearchResults={!!selectedTransaction.emailSearchResults}
            />
          )} */}

          <HandleNonEmailReceipts
            transaction={selectedTransaction}
            cancelAction={cancelAction}
            cancelKey={cancelKey}
            ignoreAction={ignoreAction}
            ignoreKey={ignoreKey}
          />
        </div>

        <TransactionNavigation selectNextTransaction={selectNextTransaction} />
      </div>
    )
  }
}

EmailOverview.propTypes = {
  selectedTransaction: transactionPropType,
  onFreeTextEmailSearch: PropTypes.func,
  onSelectEmail: PropTypes.func,
  onMarkEmail: PropTypes.func
}

export default EmailOverview
