// util funcctions for db functions
import generateId from '../util/generateId'
import { firebaseSafeRegex } from '../google/firebase'
import moment from '../util/moment'

const transaction = {
  uniqueId: transaction => {
    const idPostfix = transaction.accountId
      ? `-accountid${transaction.accountId}`
      : '-missing-account-id'
    if (!transaction.originalData) {
      return generateId(transaction, 'Missing-original-data-', idPostfix)
    }
    const idMaterial = Object.keys(transaction.originalData).map(
      key => transaction.originalData[key]
    )
    const idPrefix = `${moment(transaction.dateTime.utc, 'x')
      .tz(transaction.dateTime.timezone)
      .format('YYYYMMDD')}-${transaction.merchant}-${transaction.sum}${
      transaction.currency
    }-`.replace(firebaseSafeRegex, ',')
    return generateId(idMaterial, idPrefix, idPostfix)
  },
  getAccountId: transactionId => transactionId.split('-accountid')[1]
}

const email = {
  getEmailAccountId: emailId => emailId.split('-emailAccountId')[1]
}

const dbUtil = { transaction, email }

export default dbUtil
