import React from 'react'
import PlaidLink from 'react-plaid-link'
import {
  fetchAccountsThroughApi,
  fetchBankAuthTokenThroughApi
} from '../../api/syncBank'

const PlaidConnectButton = props => (
  <PlaidLink
    clientName="Receipt Runner"
    env="sandbox" // development
    className="button"
    style={{}}
    product={['transactions']}
    // institution={options.institution_id ? options.institution_id : null}
    token={props.public_token ? props.public_token : null}
    publicKey={process.env.REACT_APP_PLAID_PUBLIC_KEY}
    onExit={result => console.log(result)}
    onSuccess={async (public_token, metadata) => {
      // Get access token
      const plaid_access_token = await fetchBankAuthTokenThroughApi('plaid', {
        public_token
      })
      const accounts = metadata.accounts ? metadata.accounts : false
      // Get account list and store access token for those:
      await fetchAccountsThroughApi('plaid', {
        plaid_access_token,
        accounts
      })

      // TODO: This is inviting a timing issue:
      // If the update of selected account is happening
      // BEFORE the new accounts are in, then
      // we might in some cases be in a load of trouble
      if (props.updateSelectedAccount) {
        props.updateSelectedAccount(
          {
            bankApiNeedsLogin: false
          },
          true
        )
      }
    }}
  >
    {props.children}
  </PlaidLink>
)

export default PlaidConnectButton
