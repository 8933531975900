import PropTypes from 'prop-types'

const emailSearchResultPropType = PropTypes.shape({
  subject: PropTypes.string.isRequired,
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  html: PropTypes.string,
  text: PropTypes.string,
  fileName: PropTypes.string,
  mimeType: PropTypes.string,
  datetime: PropTypes.string
})

export { emailSearchResultPropType }
