import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

import { transactionPropType } from '../../domain/transaction'
import LoadingGoogleApi from './LoadingGoogleApi'
import LoginForm from '../loginForm'
import NoTransactionSelected from './NoTransactionSelected'
import LoadingTransactions from './LoadingTransactions'
import {
  CurrentlySearchingForEmails,
  StartSearchForEmails
} from './SearchingForEmails'
import NoTransactions from './NoTransactions'
import BankApiNeedsLogin from './BankApiNeedsLogin'
import ApproveAllSuggestions from './ApproveAllSuggestions'
import StartWork from './StartWork'

class InfoScreensHandler extends PureComponent {
  render() {
    // When testing, you can return all here:
    //
    // return <div id="testinfoViews">
    //             <LoadingGoogleApi />
    //             <LoginForm onLogin={this.props.onLogin} />
    //             <LoadingTransactions />
    //             <NoTransactions />
    //             <CurrentlySearchingForEmails />
    //             <StartSearchForEmails
    //               searchingForEmail={this.props.searchingForEmail}
    //               searchForTransactionEmails={this.props.searchForTransactionEmails}
    //             />
    //             <NoTransactionSelected />
    // </div>

    if (!this.props.googleApiLoaded) {
      return <LoadingGoogleApi className="infoView--fullscreen" />
    }
    if (this.props.onLogin) {
      return (
        <LoginForm
          className="infoView--fullscreen"
          onLogin={this.props.onLogin}
        />
      )
    }

    if (this.props.importingTransactionsFromApi) {
      return (
        <LoadingTransactions text="Importing transactions from your bank..." />
      )
    }

    if (this.props.loadingTransactions) {
      return <LoadingTransactions />
    }

    const infoScreens = []

    if (this.props.transactions.length === 0) {
      infoScreens.push(<NoTransactions />)
    }

    if (this.props.bankApiNeedsLogin) {
      infoScreens.push(
        <BankApiNeedsLogin
          api={this.props.bankApiNeedsLogin.api}
          options={this.props.bankApiNeedsLogin.options}
          updateSelectedAccount={this.props.updateSelectedAccount}
        />
      )
    }

    if (this.props.searchingForEmail) {
      infoScreens.push(<CurrentlySearchingForEmails />)
    } else if (!this.props.allTransactionsSearchedForEmails) {
      infoScreens.push(
        <StartSearchForEmails
          searchingForEmail={this.props.searchingForEmail}
          searchForTransactionEmails={this.props.searchForTransactionEmails}
        />
      )
    }

    if (this.props.anySuggestedTransactions) {
      infoScreens.push(
        <ApproveAllSuggestions
          markAllSuggestionsAsReadyForExport={
            this.props.markAllSuggestionsAsReadyForExport
          }
          transactions={this.props.transactions}
          selectNextTransaction={this.props.selectNextTransaction}
        />
      )
    } else if (
      !this.props.allTransactionsReady &&
      this.props.allTransactionsSearchedForEmails
    ) {
      infoScreens.push(
        <StartWork
          markTransactionAsReadyForExport={
            this.props.markTransactionAsReadyForExport
          }
          transactions={this.props.transactions}
          selectNextTransaction={this.props.selectNextTransaction}
        />
      )
    }

    if (!this.props.selectedTransaction && this.props.firstTimeLogin) {
      // Todo: Add support for first time login or other way to toggle this one off.
      infoScreens.push(<NoTransactionSelected />)
    }
    return (
      <div>
        {infoScreens.map(infoScreen => (
          <div key={'infoScreen-' + infoScreen.type.name}>{infoScreen}</div>
        ))}
      </div>
    )
  }
}

InfoScreensHandler.propTypes = {
  transactions: PropTypes.arrayOf(transactionPropType),
  selectedTransaction: transactionPropType,
  searchingForEmail: PropTypes.bool,
  googleApiLoaded: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  onLogin: PropTypes.func
}

export default InfoScreensHandler
