import parseDecimalNumber from 'parse-decimal-number'

const transactionsKey = 'Transaksjon'
const debitCardKey = 'Debit card'
const giroKey = 'Giro'
const exchangeRateKey = 'Valutakurs'
const norwegianCurrencyKey = 'NOK'
const dnbDateFormat = 'DD.MM.YYYY'
const reservedTransactionKey = 'R'
const bankChargeKey = 'Omkostninger'

const standardizeCurrencyFormat = (currencyString = '') => {
  // parseDecimalNumber parses weird strings into a float. Uses DNB specific formatting cue in second parameter:
  // A two-character string consisting of the thousands character followed by the decimal point character, e.g. '.,'
  const currencyFloat = parseDecimalNumber(currencyString, '.,')
    .toFixed(2)
    .toString()
    .replace(',', '.')
    .replace('-', '')

  return currencyFloat
}

const isReservedTransaction = dnbJson =>
  dnbJson.status && dnbJson.status === reservedTransactionKey

const isBankCharge = dnbJson => dnbJson.type && dnbJson.type === bankChargeKey

const isForeignTransaction = dnbJson =>
  dnbJson.type &&
  dnbJson.textMore &&
  dnbJson.type.includes(transactionsKey) &&
  dnbJson.textMore.includes(exchangeRateKey)

const isDomesticTransaction = dnbJson =>
  dnbJson.type &&
  (dnbJson.type.includes(transactionsKey) ||
    dnbJson.type.includes(debitCardKey)) &&
  !dnbJson.textMore.includes(exchangeRateKey)

const isGiroTransaction = dnbJson => dnbJson.type && dnbJson.type === giroKey

const dnbCsvAccountLinesHeaders = ['number', 'name']

const dnbCsvTransactionLinesHeaders = [
  'date',
  'textMore',
  'status',
  'type',
  'interestDate',
  'out',
  'in',
  'reference',
  'bankReference'
]

const equationRegex = new RegExp('=', 'g')

const dnbCsvAccountLinesCleaner = csvString =>
  csvString
    .replace(equationRegex, '')
    .split('\n')
    .splice(1, 1)
    .join('\n') // Only include second line

const dnbCsvTransactionLinesCleaner = csvString =>
  csvString
    .replace(equationRegex, '')
    .split('\n')
    .slice(5)
    .join('\n') // Cut first 4 lines

export {
  standardizeCurrencyFormat,
  isReservedTransaction,
  isBankCharge,
  isForeignTransaction,
  isDomesticTransaction,
  isGiroTransaction,
  norwegianCurrencyKey,
  transactionsKey,
  dnbDateFormat,
  reservedTransactionKey,
  bankChargeKey,
  dnbCsvAccountLinesHeaders,
  dnbCsvTransactionLinesHeaders,
  dnbCsvAccountLinesCleaner,
  dnbCsvTransactionLinesCleaner
}
