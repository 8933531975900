const googleConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'autobilag.firebaseapp.com',
  databaseURL: 'https://autobilag.firebaseio.com',
  projectId: 'autobilag',
  storageBucket: 'autobilag.appspot.com',
  messagingSenderId: '12102827182',
  scopes: [
    'https://www.googleapis.com/auth/gmail.readonly',
    'https://www.googleapis.com/auth/userinfo.email'
  ],
  clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest']
}

const appConfig = {
  apiUri:
    process.env.NODE_ENV === 'production'
      ? 'https://api.autobilag.no'
      : 'http://localhost:8080'
}

export { appConfig as default, googleConfig }
