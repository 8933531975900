// User.js, database commands for user space
import * as firebase from 'firebase/app'
import { firebaseSafeJson } from '../google/firebase'
import database from './index'
import dbUtil from './util'
import moment from '../util/moment'

const generateDataRefs = (data, baseRef) => {
  const dataRefs = {}
  Object.keys(data).forEach(key => {
    switch (key) {
      case 'attachments':
        // Attachments need to be stored so they don't overwrite the data when the email is stored for a transaction where it's not selected and therefore doesn't run fetchAttachments
        data.attachments.forEach((attachment, index) => {
          Object.keys(attachment).forEach(attachmentKey => {
            dataRefs[`${baseRef}/data/attachments/${index}/${attachmentKey}`] =
              attachment[attachmentKey]
          })
        })
        break
      case 'selected':
      case 'score':
      case 'perfectMatch':
        // ignore these
        break
      default:
        dataRefs[`${baseRef}/data/${key}`] = data[key]
    }
  })
  return dataRefs
}

const storeSearchResult = async (
  emailSearch,
  transactionId,
  uid = firebase.auth().currentUser.uid
) => {
  if (!emailSearch || !transactionId || !uid) return
  const emailUpdates = {}
  const transactionUpdate = {}
  emailSearch.emailSearchResults.forEach(email => {
    const emailRef = `/user/${uid}/emailAccounts/${email.accountId}/emails/${
      email.id
    }`
    emailUpdates[`${emailRef}/id`] = email.id
    const dataRefs = generateDataRefs(email, emailRef)
    Object.keys(dataRefs).forEach(key => {
      emailUpdates[key] = dataRefs[key]
    })
    emailUpdates[`${emailRef}/scoredByTransactions/${transactionId}`] =
      email.score
    emailUpdates[`${emailRef}/selectedByTransactions/${transactionId}`] =
      email.selected
    transactionUpdate[`emailSearchResults/${email.id}/`] = {
      id: email.id,
      subject: email.subject,
      score: email.score,
      datetime: email.datetime,
      snippet: email.snippet,
      from: email.from,
      to: email.to,
      selected: email.selected
    }
  })
  transactionUpdate[`emailSearchQuery`] = emailSearch.emailSearchQuery
  transactionUpdate[`perfectMatch`] = emailSearch.perfectMatch
  transactionUpdate[`searchedForEmails`] = moment().format('x')

  database.transaction.update(transactionUpdate, transactionId)
  return firebase
    .database()
    .ref()
    .update(firebaseSafeJson(emailUpdates))
}

async function updateEmail(
  email,
  subPath = '',
  update,
  uid = firebase.auth().currentUser.uid
) {
  const emailUpdates = {}
  const emailRef = `/user/${uid}/emailAccounts/${email.data.accountId}/emails/${
    email.id
  }${subPath}`
  for (const key in update) {
    if (update.hasOwnProperty(key)) {
      emailUpdates[`${emailRef}/${key}`] = update[key]
    }
  }
  return firebase
    .database()
    .ref()
    .update(firebaseSafeJson(emailUpdates))
}

async function setSelectEmailTo(
  select,
  emailId,
  transactionId,
  transactionReadyForExport = false,
  uid = firebase.auth().currentUser.uid
) {
  const accountId = dbUtil.email.getEmailAccountId(emailId)

  const emailUpdates = {}
  const transactionUpdate = {}
  const emailRef = `/user/${uid}/emailAccounts/${accountId}/emails/${emailId}`
  emailUpdates[`${emailRef}/selectedByTransactions/${transactionId}`] = select
    ? true
    : null

  transactionUpdate[`emailSearchResults/${emailId}/selected`] = select
    ? true
    : null

  transactionUpdate[`readyForExport`] = transactionReadyForExport ? true : null

  database.transaction.update(transactionUpdate, transactionId)

  return firebase
    .database()
    .ref()
    .update(firebaseSafeJson(emailUpdates))
}

async function listenToTransactionEmails(
  transaction,
  finishedInitialLoad = input => null,
  handleEmail = input => null,
  uid = firebase.auth().currentUser.uid
) {
  await stopListenToTransactionEmails(transaction, uid)
  // startDate and endDate are moment Date-objects
  if (!uid || !transaction) return

  const emailRefs = Object.values(transaction.emailSearchResults).map(email => {
    const emailAccountId = dbUtil.email.getEmailAccountId(email.id)
    return firebase
      .database()
      .ref(`/user/${uid}/emailAccounts/${emailAccountId}/emails/${email.id}`)
  })
  const promises = emailRefs.map(ref => {
    ref.on('value', snapshot => {
      const email = snapshot.val()
      if (email) {
        handleEmail(email)
      }
    })
    return ref.once('value', snapshot => {
      finishedInitialLoad(snapshot.val())
      return snapshot.val()
    })
  })

  return Promise.all(promises)
}

async function stopListenToTransactionEmails(
  transaction,
  uid = firebase.auth().currentUser.uid
) {
  if (!uid || !transaction) return
  const promises = Object.values(transaction.emailSearchResults).map(email => {
    const emailAccountId = dbUtil.email.getEmailAccountId(email.id)
    return firebase
      .database()
      .ref(`/user/${uid}/emailAccounts/${emailAccountId}/emails/${email.id}`)
      .off()
  })
  return Promise.all(promises)
}

const email = {
  storeSearchResult,
  listenToTransactionEmails,
  updateEmail,
  setSelectEmailTo
}

export default email
