import React from 'react'

import InfoView from '../../sharedComponents/InfoView'

const LoadingTransactions = props => (
  <InfoView
    text={props.text ? props.text : 'Loading transactions...'}
    icon="loading"
  />
)

export default LoadingTransactions
