import moment from '../util/moment'

export const gmailSearchDateFormat = 'YYYY-MM-DD'
const receiptTerms =
  'faktura kvittering billing payment receipt statement bill invoice order'
const subjectIfyStringList = string =>
  `subject:${string.split(' ').join(' subject:')}`

export function createQueryFromTransaction(dnbTransaction) {
  const { currency, date, merchant, sum } = dnbTransaction
  const allSumFormats = getAllSumFormats(sum).join(' ')
  const allCurrencyFormats = getAllCurrencyFormat(currency).join(' ')
  const { start, end } = createDateRangeFrom(date)
  return `(from:${merchant} {${allCurrencyFormats}} {${allSumFormats}} {${receiptTerms}} {filename:pdf} after:${start} before:${end})`
}

export function createQueryFromMerchant({ merchant, date }) {
  const { start, end } = createDateRangeFrom(date)
  return `(from:${merchant} after:${start} before:${end})`
}

export function createQueryFromMerchantInSubject({ merchant, date }) {
  const { start, end } = createDateRangeFrom(date)
  return `(${subjectIfyStringList(merchant)} after:${start} before:${end})`
}

export function createQueryFromCurrencyAndSum({ currency, sum, date }) {
  const { start, end } = createDateRangeFrom(date)
  const allSumFormats = getAllSumFormats(sum).join(' ')
  const allCurrencyFormats = getAllCurrencyFormat(currency).join(' ')
  return `({${allCurrencyFormats}} {${allSumFormats}} after:${start} before:${end})`
}

export function createQueryFromSum({ sum, date }) {
  const { start, end } = createDateRangeFrom(date)
  const allSumFormats = getAllSumFormats(sum).join(' ')
  return `({${allSumFormats}} after:${start} before:${end})`
}

export function createQueryFromCurrency({ currency, date }) {
  const { start, end } = createDateRangeFrom(date)
  const allCurrencyFormats = getAllCurrencyFormat(currency).join(' ')
  return `({${allCurrencyFormats}} after:${start} before:${end})`
}

export function createQueryForReceiptTerms({ date }) {
  const { start, end } = createDateRangeFrom(date)
  return `({${receiptTerms}} after:${start} before:${end})`
}

export function createQueryForReceiptTermsInSubject({ date }) {
  const { start, end } = createDateRangeFrom(date)
  return `({${subjectIfyStringList(
    receiptTerms
  )}} after:${start} before:${end})`
}

export function createQueryForAttachments({ date }) {
  const { start, end } = createDateRangeFrom(date)
  return `({filename:pdf} after:${start} before:${end})`
}

export function createQueryFromDescription({ description, date }) {
  const { start, end } = createDateRangeFrom(date)
  return `(${description} after:${start} before:${end})`
}

export function createQueryFromDescriptionInSubject({ description, date }) {
  const { start, end } = createDateRangeFrom(date)
  return `(${subjectIfyStringList(description)} after:${start} before:${end})`
}

export function getAllSumFormats(sumString) {
  if (!sumString) {
    return []
  }
  const results = [sumString]

  const isAboveThousand = sumString > 1000 // JS, lol
  if (isAboveThousand) {
    results.push(parseFloat(sumString).toLocaleString('en')) // Adds variants with thousand markers as , (variant with . provided by swappedpunctuation)
    results.push(
      parseFloat(sumString).toLocaleString('en', { minimumFractionDigits: 2 })
    ) // Adds variant with thousand marker as , + leading zeros
    results.push(parseFloat(sumString).toLocaleString('fr')) // Adds variants with thousand marker as space
    results.push(
      parseFloat(sumString).toLocaleString('fr', { minimumFractionDigits: 2 })
    ) // Adds variant with thousand marker as space + leading zeros
  }

  const hasZeroAsPrefix = sumString.startsWith('0')
  if (hasZeroAsPrefix) {
    results.push(sumString.substr(1))
  }

  if (!hasZeroAsPrefix) {
    const withoutDecimals = sumString.substr(0, sumString.indexOf('.'))
    results.push(withoutDecimals)
  }

  const swappedPunctuationResults = results.map(result =>
    result.replace(/[,.]/g, match => (match === ',' ? '.' : ','))
  )

  return [...swappedPunctuationResults, ...results].map(result => `"${result}"`) // Adding "" to force Gmail search to see them as whole entities
}

export function createDateRangeFrom(date = moment(), daysToSubtract = 7) {
  const parsedDate = moment(date).isValid() ? moment(date) : moment()
  return {
    start: moment(parsedDate)
      .subtract(daysToSubtract, 'days')
      .format(gmailSearchDateFormat),
    end: moment(parsedDate).format(gmailSearchDateFormat)
  }
}

export function getAllCurrencyFormat(currency = '') {
  switch (currency) {
    case 'NOK':
      return ['NOK', 'kr']
    case 'USD':
      return ['USD', 'usd', '$']
    case 'EUR':
      return ['EUR', 'euro', '€']
    default:
      return [currency]
  }
}
