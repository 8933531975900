// User.js, database commands for user space
import * as firebase from 'firebase/app'
import moment from 'moment'

async function create(user) {
  const uid = user.uid
  const updates = {}

  if (!user || !uid) return
  updates[`/user/${uid}/profile`] = user
  return firebase
    .database()
    .ref()
    .update(updates)
}

const updatesForConfig = (config, uid = firebase.auth().currentUser.uid) => {
  const updates = {}
  for (const key in config) {
    if (config.hasOwnProperty(key)) {
      moment.isMoment(config[key])
        ? (updates[`/user/${uid}/config/${key}`] = config[key].format('x'))
        : (updates[`/user/${uid}/config/${key}`] = config[key])
    }
  }
  return updates
}

async function config(config, uid = firebase.auth().currentUser.uid) {
  if (!config || !uid) return
  const updates = updatesForConfig(config, uid)
  return firebase
    .database()
    .ref()
    .update(updates)
}

async function listenToConfig(
  uid = firebase.auth().currentUser.uid,
  handleConfig = input => null,
  finishedInitialLoad = () => null
) {
  await stopListenToConfig(uid)
  if (!uid) return

  const listen = firebase.database().ref(`/user/${uid}/config/`)

  listen.on('value', snapshot => {
    handleConfig(snapshot.val())
  })

  return listen.once('value', () => {
    finishedInitialLoad()
  })
}

async function stopListenToConfig(uid = firebase.auth().currentUser.uid) {
  if (!uid) return
  return firebase
    .database()
    .ref(`/user/${uid}/config/`)
    .off()
}

async function del(uid) {
  // no default is sane for del()
  if (!uid) return
  return firebase
    .database()
    .ref(`/user/${uid}`)
    .remove()
  // TODO: Remove login for user too
}

const user = { create, del, config, listenToConfig }

export default user
