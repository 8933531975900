import React from 'react'
import { Table } from 'antd'
import PropTypes from 'prop-types'
import { accountPropType } from '../../domain/account'

const mapToDataSourceFormat = (account, index) => ({
  key: index,
  name: account.name,
  number: account.number,
  account
})

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: name => <strong>{name}</strong>
  },
  {
    title: 'Number',
    dataIndex: 'number',
    key: 'number'
  }
]

const AccountChooser = props => {
  const { accounts, onSelectAccount } = props
  if (!accounts || accounts.length === 0) return null
  const dataSource = accounts.map(mapToDataSourceFormat)
  return (
    <Table
      showHeader={true}
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      className="accountChooser"
      onRow={item => ({
        onClick: () => {
          onSelectAccount(item.account)
        }
      })}
    />
  )
}

AccountChooser.propTypes = {
  accounts: PropTypes.arrayOf(accountPropType),
  onSelectAccount: PropTypes.func
}

export default AccountChooser
