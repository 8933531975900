import { googleConfig } from '../config'

function getGapiAuthInstance() {
  return window.gapi && window.gapi.auth2 && window.gapi.auth2.getAuthInstance()
}

export function checkIfLoggedIn() {
  const authInstance = getGapiAuthInstance()
  return authInstance && authInstance.isSignedIn.get()
}

export async function initializeGapi() {
  const googleClientConfig = {
    apiKey: googleConfig.apiKey,
    clientId: googleConfig.clientId,
    scope: googleConfig.scopes.join(' '),
    discoveryDocs: googleConfig.discoveryDocs
  }
  await window.gapi.client.init(googleClientConfig)
}

export async function gapiLogin() {
  const authInstance = getGapiAuthInstance()
  if (!authInstance) {
    throw new Error('Not logged in')
  }
  await authInstance.signIn()
  const isSignedIn = authInstance.isSignedIn.get()
  if (!isSignedIn) {
    throw new Error(`Couldn't log in, please try again :(`)
  }
  const googleUser = authInstance.currentUser.get()
  return googleUser.getAuthResponse().id_token
}

export async function gapiLogout() {
  const authInstance = getGapiAuthInstance()
  if (authInstance) {
    await authInstance.signOut()
  }
}
