import { Icon } from 'antd'
import React from 'react'

import './InfoView.css'

const InfoView = props => (
  <div
    className={props.color ? 'infoView infoView--' + props.color : 'infoView'}
  >
    {props.icon ? <Icon type={props.icon} className="infoView__icon" /> : null}
    <p className="infoView__text">{props.text}</p>
    <div className="infoView__children">{props.children}</div>
  </div>
)

export default InfoView
