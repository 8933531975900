import { Upload, Icon, message } from 'antd'
import React, { PureComponent } from 'react'

import {
  dnbCsvTransactionLinesHeaders,
  dnbCsvAccountLinesHeaders,
  dnbCsvTransactionLinesCleaner,
  dnbCsvAccountLinesCleaner
} from '../../domain/dnb'
import mapTransactionFromDNB from '../../util/mapTransactionFromDNB'
import mapAccountFromDNB from '../../util/mapAccountFromDNB'
import parseCSV from '../../util/parseCSV'
import readFile from '../../util/readFile'

class FileUpload extends PureComponent {
  beforeUpload = async file => {
    if (!file.type === 'text/csv' || !file.type === 'text/plain') {
      message.error(
        "We only support DNBs 'Til fil' account statement. The file is usually named kontoutskrift.txt",
        10
      )
      return
    }
    try {
      const content = await readFile(file)
      const dnbTransactionParser = parseCSV(
        mapTransactionFromDNB,
        dnbCsvTransactionLinesHeaders,
        dnbCsvTransactionLinesCleaner
      )
      const transactions = await dnbTransactionParser(content)
      const dnbAccountParser = parseCSV(
        mapAccountFromDNB,
        dnbCsvAccountLinesHeaders,
        dnbCsvAccountLinesCleaner
      )
      const accountInfoArray = await dnbAccountParser(content)
      const accountInfo = accountInfoArray[0]
      await this.props.onTransactionFileParsed(
        file.name,
        transactions,
        accountInfo
      )
    } catch (error) {
      message.error(error.message, 10)
    }
    return false
  }

  handleChange = () => {}

  render() {
    return (
      <Upload
        accept="text/csv, text/plain"
        name="fileUpload"
        showUploadList={false}
        action=""
        className="button fileupload"
        beforeUpload={this.beforeUpload}
        onChange={this.handleChange}
      >
        Upload bank statement (.csv) <Icon type="file-text" />
      </Upload>
    )
  }
}

export default FileUpload
