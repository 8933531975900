import jschardet from 'jschardet'
import iconvlite from 'iconv-lite'

export default function(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onerror = error => reject(error)
    fileReader.onloadend = event => {
      const rawFileContent = event.target.result
      const { encoding } = jschardet.detect(rawFileContent)
      const content = iconvlite.decode(rawFileContent, encoding)
      resolve(content)
    }
    fileReader.onabort = () => reject(new Error('Upload was cancelled'))
    fileReader.readAsBinaryString(file)
  })
}
