import './App.css' // Needs to be before components, as it imports antd styling that needs to be overwritten

import { message } from 'antd'
import React, { PureComponent } from 'react'
import moment from './util/moment'
import { Route, Switch } from 'react-router-dom'

import { checkIfLoggedIn } from './google/gapi'
import { initialState } from './util/app'
import { initializeFirebase, firebaseSafeRegex } from './google/firebase'
import { logout, restoreSession } from './util/auth'
import {
  searchForTransaction,
  searchForText,
  fetchAttachments
} from './domain/gmail'
import {
  hasAllSearchedForEmails,
  hasAnySuggestedEmails,
  hasSelectedEmail
} from './domain/transaction'
import GoogleApiLoader from './sharedComponents/GoogleApiLoader'
import HeaderBar from './scenes/header'
import InfoScreensHandler from './scenes/infoScreens'
import TransactionOverview from './scenes/transactionOverview'
import sendTransaction, { startNewJob, finishJob } from './api/pdfConversion'

import {
  fetchTransactionsThroughApi,
  alreadyFetchedTransactionsForDates
} from './api/syncBank'

import database from './database'

class App extends PureComponent {
  constructor() {
    super()
    this.state = { ...initialState }
    initializeFirebase()
  }

  // //
  // App methods

  onGoogleApiLoaded = async () => {
    if (checkIfLoggedIn()) {
      const user = await restoreSession()
      await this.onLogin(user)
    }
    this.setState({ googleApiLoaded: true })
  }

  onLogout = async () => {
    try {
      await logout()
      this.setState({
        ...initialState,
        googleApiLoaded: this.state.googleApiLoaded
      })
      message.info('You are logged out')
    } catch (error) {
      message.error(`Couldn't log you out. Please try again :(`)
    }
  }

  onLogin = async user => {
    const userData = {
      uid: user.uid ? user.uid : false,
      name: user.displayName ? user.displayName : false,
      email: user.email ? user.email : false,
      photo: user.photoUrl ? user.photoUrl : false,
      phone: user.phoneNumber ? user.phoneNumber : false
    }
    await Promise.all([
      database.user.create(userData),
      database.user.listenToConfig(userData.uid, config =>
        this.setState({ config })
      ),
      database.account.listenAll(
        user.uid,
        accounts => this.loadAccounts(accounts),
        accounts => this.onAccountsFirstLoad(accounts, userData.uid)
      )
    ])
    this.setState({ isLoggedIn: true, user: userData })
  }

  // //
  // Bank account methods

  loadAccounts = accounts => {
    // Update account list
    this.setState({ accounts })
    // Update selected account, if set:
    const selectedAccount = this.state.selectedAccount
    if (!selectedAccount || !selectedAccount.id) return

    const newSelectedAccountData = accounts.find(
      account => account.id === selectedAccount.id
    )
    this.setState({
      selectedAccount: {
        ...selectedAccount,
        ...newSelectedAccountData
      }
    })
  }

  onSelectAccount = (selectedAccount, uid) => {
    const startDate = selectedAccount.startDate
      ? moment(selectedAccount.startDate, 'x')
      : moment().subtract(1, 'years')
    const endDate = selectedAccount.endDate
      ? moment(selectedAccount.endDate, 'x')
      : moment()
    this.setSelectedAccountDateRange(
      [startDate, endDate],
      undefined,
      selectedAccount,
      uid
    )
  }

  onAccountsFirstLoad = (accounts, uid) => {
    if (accounts.length === 1) {
      const selectedAccount = accounts[0]
      this.onSelectAccount(selectedAccount, uid)
    }
  }

  clearSelectedAccount = async () => {
    const selectedAccount = this.state.selectedAccount
    const uid = this.state.user.uid
    database.transaction.stopListen(selectedAccount.id, uid)
    this.setState({
      selectedAccount: null,
      transactions: []
    })
  }

  setSelectedAccountDateRange = async (
    [startDate = moment().subtract(1, 'years'), endDate = moment()],
    ANTD_DATE_RANGES,
    selectedAccount = this.state.selectedAccount,
    uid = this.state.user.uid
  ) => {
    this.setState({
      loadingTransactions: true,
      selectedTransactionIndex: -1,
      downloadUrl: false
    })

    // Setting so they are a moment()
    startDate = moment.isMoment(startDate) ? startDate : moment(startDate)
    endDate = moment.isMoment(endDate) ? endDate : moment(endDate)

    database.transaction.listen(
      selectedAccount.id,
      uid,
      transactions => {
        this.setState({
          transactions,
          allTransactionsSearchedForEmails: this.state.importingNewTransactions
            ? hasAllSearchedForEmails(transactions)
            : this.state.allTransactionsSearchedForEmails,
          anySuggestedTransactions: this.state.searchedForNewEmails
            ? hasAnySuggestedEmails(transactions)
            : this.state.anySuggestedTransactions,
          importingNewTransactions: false,
          searchedForNewEmails: false
        })
      },
      startDate,
      endDate,
      transactions => {
        this.setState({
          loadingTransactions: false,
          allTransactionsSearchedForEmails: hasAllSearchedForEmails(
            transactions
          ),
          anySuggestedTransactions: hasAnySuggestedEmails(transactions)
        })
      }
    )
    database.account.update(
      selectedAccount.id,
      {
        startDate: startDate.format('x'),
        endDate: endDate.format('x')
      },
      uid
    )
    this.setState({
      selectedAccount: {
        ...selectedAccount,
        startDate,
        endDate
      }
    })

    if (
      !alreadyFetchedTransactionsForDates(selectedAccount, startDate, endDate)
    ) {
      this.setState({
        importingTransactionsFromApi: true,
        loadingTransactions: true
      })
      try {
        await fetchTransactionsThroughApi(selectedAccount, {
          startDate,
          endDate
        })
      } catch (error) {
        // Error structure:
        // {
        //     error: boolean,
        //     options?: { public_token?, type }
        //     trigger_new_login?: boolean
        // }
        if (error.trigger_new_login) {
          this.updateSelectedAccount({
            bankApiNeedsLogin: {
              api: error.api,
              options: {
                ...error.options,
                institution_id: selectedAccount.api
                  ? selectedAccount.api.institution_id
                  : null
              }
            }
          })
        } else {
          // Nothing to do, really...
        }
      }
      this.setState({
        importingTransactionsFromApi: false,
        loadingTransactions: false
      })
    }
  }

  updateSelectedAccount = (data = {}, refreshTransactions = false) => {
    // Update with new data, and trigger onSelectAccount
    const selectedAccount = {
      ...this.state.selectedAccount,
      ...data
    }
    this.setState({
      selectedAccount
    })
    if (refreshTransactions) {
      this.onSelectAccount(selectedAccount, this.state.user.uid)
    }
  }

  // //
  // Transaction methods

  updateSelectedTransaction = async updateFunction => {
    const selectedTransaction = this.state.transactions[
      this.state.selectedTransactionIndex
    ]
    const updateData = await updateFunction(selectedTransaction)
    database.transaction.update(updateData, selectedTransaction.id)
  }

  markTransactionAsReadyForExport = async (transactionId, ready = true) => {
    return await database.transaction.update(
      { readyForExport: ready ? true : null },
      transactionId
    )
  }

  storeEmailInState = email => {
    const emails = {}
    emails[email.id] = email
    this.setState((state, props) => ({
      emails: { ...state.emails, ...emails }
    }))
  }

  onSelectTransaction = async selectedTransaction => {
    // Unselect if selectedTransaction is false
    if (!selectedTransaction) {
      return this.setState({
        selectedTransactionIndex: -1
      })
    }

    // Load existing email data:
    database.email.listenToTransactionEmails(
      selectedTransaction,
      () => null,
      this.storeEmailInState
    )

    // Select transasction:
    return this.setState({
      selectedTransactionIndex: this.state.transactions.indexOf(
        selectedTransaction
      )
    })
  }

  scrollToTransaction = transactionId => {
    if (!transactionId) return
    // NOT DONE IN A REACT WAY
    // TODO: Migrate to refs
    const transactionDom = document.getElementById(
      `transactionListTable__row--id-${transactionId.replace(
        /[^A-Za-z0-9]/g,
        ''
      )}`
    )
    // Scrolls to selected transaction
    transactionDom.scrollIntoView({ behavior: 'smooth' })
  }

  selectNextTransaction = async (
    onlyUnfinished = false,
    selectPrevious = false
  ) => {
    const currentIndex = this.state.selectedTransactionIndex
    const transactions = this.state.transactions
    const maxIndex = transactions.length
    const increment = index => (selectPrevious ? --index : ++index)
    const nextIndex = () => {
      if (onlyUnfinished) {
        // check if next is unfinished, if not, check for next one again.
        let checkIndex = increment(currentIndex)
        while (
          checkIndex !== currentIndex &&
          checkIndex > -1 &&
          checkIndex <= maxIndex &&
          transactions[checkIndex] &&
          transactions[checkIndex].readyForExport
        ) {
          checkIndex = checkIndex === maxIndex ? 0 : increment(checkIndex)
        }
        // If all are finished, return -1
        if (
          (currentIndex === checkIndex &&
            transactions[checkIndex] &&
            transactions[checkIndex].readyForExport) ||
          !transactions[checkIndex]
        ) {
          message.info(
            onlyUnfinished
              ? `No unfinished transactions! We're all set for export`
              : 'No more transactions'
          )
          checkIndex = -1
        }
        return checkIndex
      }
      return increment(currentIndex)
    }
    const checkIndex = nextIndex()
    await this.onSelectTransaction(transactions[checkIndex]) // Handles nonexistient transactions by deselcting all
    if (transactions[checkIndex]) {
      this.scrollToTransaction(transactions[checkIndex].id)
    }
  }

  onTransactionFileParsed = async (
    fileName,
    parsedTransactions,
    accountInfo
  ) => {
    this.setState({
      loadingTransactions: true,
      importingNewTransactions: true
    })

    const transactions = parsedTransactions.map((transaction, index) => ({
      ...transaction
    }))

    // Create or update account info:
    const userId = this.state.user.uid
    if (accountInfo) database.account.create(accountInfo, userId)
    if (transactions.length > 0) {
      await database.transaction.createAll(
        transactions,
        accountInfo.number.replace(firebaseSafeRegex, ''),
        userId
      )
      this.setState({
        loadingTransactions: false
      })
    }
  }

  markAllSuggestionsAsReadyForExport = async () => {
    const transactionsWithSuggestions = this.state.transactions.filter(
      transaction =>
        !transaction.readyForExport && hasSelectedEmail(transaction)
    )

    await transactionsWithSuggestions.forEach(transaction => {
      this.markTransactionAsReadyForExport(transaction.id, true)
    })
    this.setState({
      anySuggestedTransactions: false
    })
  }

  // //
  // Transaction email methods

  promiseSingleTransactionEmails = async (transaction, index) => {
    return new Promise(async (resolve, reject) => {
      try {
        const emailSearchResults = await searchForTransaction.bind(this)(
          transaction,
          index
        )
        await database.email.storeSearchResult(
          emailSearchResults,
          transaction.id
        )
        resolve(emailSearchResults)
      } catch (error) {
        reject(error)
      }
    })
  }

  handleSearchForSignleTransactionEmails = async transaction => {
    const emailSearchResults = await this.promiseSingleTransactionEmails(
      transaction,
      null
    )
    // We have to start listening to emails using the new tx data
    const newTransactionData = { ...transaction, ...emailSearchResults }

    return database.email.listenToTransactionEmails(
      newTransactionData,
      () => null,
      this.storeEmailInState
    )
  }

  searchForTransactionEmails = async (transaction = null) => {
    if (
      !this.state.googleApiLoaded ||
      !this.state.isLoggedIn ||
      this.state.transactions.length === 0
    ) {
      return
    }
    // If transaction is set, search for this tx only
    if (transaction) {
      this.setState({ searchingForEmail: true })
      await this.handleSearchForSignleTransactionEmails(transaction)
      return this.setState({
        searchingForEmail: false,
        allTransactionsSearchedForEmails: hasAllSearchedForEmails(
          this.state.transactions
        ),
        anySuggestedTransactions: hasAnySuggestedEmails(this.state.transactions)
      })
    }

    // Otherwise search for all transactions
    const { transactions, selectedTransactionIndex } = this.state
    this.setState({ searchingForEmail: true, searchedForNewEmails: true })

    const emailSearchPromises = transactions.map((transaction, index) => {
      if (transaction.readyForExport || transaction.searchedForEmails)
        return null
      // If its the selected tx, we have to listen to emails again:
      if (selectedTransactionIndex === index) {
        return this.handleSearchForSignleTransactionEmails(transaction)
      }
      return this.promiseSingleTransactionEmails(transaction, index)
    })
    await Promise.all(emailSearchPromises)
    return this.setState({
      searchingForEmail: false,
      allTransactionsSearchedForEmails: true,
      anySuggestedTransactions: hasAnySuggestedEmails(this.state.transactions)
    })
  }

  onMarkEmail = (selectedEmail, userInitiated = false) => {
    this.setState({ downloadUrl: undefined }) // reset state for completed job
    const selectedTransaction = this.state.transactions[
      this.state.selectedTransactionIndex
    ]
    let transactionReadyForExport = false
    if (userInitiated && !selectedEmail.selected) {
      transactionReadyForExport = true
    }
    if (userInitiated && selectedEmail.selected) {
      // Check if there are other emails selected, if not, set readyForExport to false
      const selectedEmailCount = Object.values(
        selectedTransaction.emailSearchResults
      ).filter(emailSearchResult => emailSearchResult.selected).length
      transactionReadyForExport = selectedEmailCount === 1 ? false : true
    }
    database.email.setSelectEmailTo(
      !selectedEmail.selected,
      selectedEmail.id,
      selectedTransaction.id,
      transactionReadyForExport
    )
  }

  onSelectEmail = async selectedEmail => {
    const email = this.state.emails[selectedEmail.id]
    // First get the full email data, if we don't already have it:
    if (!email) {
      // email not loaded yet, just return false for now
      return false
    }

    // Wait for load attachments for email:

    const needToLoadAttachments = email.data.attachments
      ? !email.data.attachments.isLoaded
      : false
    if (!needToLoadAttachments) {
      return
    }
    this.updateSelectedTransaction(async transaction => ({
      loadingAttachments: true
    }))
    const attachments = await fetchAttachments(email.data)
    await database.email.updateEmail(email, '/data', { attachments })
    this.updateSelectedTransaction(async transaction => ({
      loadingAttachments: false
    }))
  }

  onFreeTextEmailSearch = query => {
    this.updateSelectedTransaction(async transaction => {
      const emailSearch = await searchForText(query)
      await database.email.storeSearchResult(emailSearch, transaction.id)
    })
  }

  // //
  // Export methods

  exportReceiptsToPDFs = async (newJobOptions = {}) => {
    if (this.state.downloadUrl && newJobOptions.downloadAgain) {
      window.location.href = this.state.downloadUrl
      return
    }
    const accountId = this.state.selectedAccount.id
    const uid = this.state.user.uid
    this.setState({ uploading: true })

    const emails = {}
    const emailPromises = []
    // Load email data for all selected transactions:
    this.state.transactions.forEach(async transaction => {
      // If any of the emails are selected:
      if (!transaction.readyForExport) return
      emailPromises.push(
        database.email.listenToTransactionEmails(
          transaction,
          async email => {
            emails[email.id] = email
          },
          this.storeEmailInState
        )
      )
    })

    await Promise.all(emailPromises)

    const jobId = await startNewJob(newJobOptions)

    const transactionUploadPromises = this.state.transactions.map(
      async transaction => {
        if (!transaction.readyForExport) return transaction

        // Fetch email data for transaction:
        Object.keys(transaction.emailSearchResults).forEach(emailId => {
          transaction.emailSearchResults[emailId] = emails[emailId]
            ? {
                ...transaction.emailSearchResults[emailId],
                ...emails[emailId].data
              }
            : transaction.emailSearchResults[emailId]
          if (!emails[emailId]) {
            message.error(
              `Couldn't find email locally. Export might be funky :/ Try reloading the app`
            )
          }
        })

        const exported = await sendTransaction(jobId, transaction)
        if (exported) {
          // Todo: Handle failed exports
          database.transaction.update(
            { exported },
            transaction.id,
            accountId,
            uid
          )
        }
        return {
          ...transaction,
          exported
        }
      }
    )
    await Promise.all(transactionUploadPromises)
    const url = await finishJob(jobId)
    if (url) {
      this.setState({ downloadUrl: url, uploading: false })
      window.location.href = url
    } else {
      this.setState({ uploading: false })
    }
  }

  // //
  // Render methods

  Main = () => {
    const {
      selectedTransactionIndex,
      transactions,
      accounts,
      isLoggedIn
    } = this.state
    const selectedTransaction = transactions[selectedTransactionIndex]

    return (
      <main className="App__Main">
        <GoogleApiLoader onGoogleApiLoaded={this.onGoogleApiLoaded} />
        <TransactionOverview
          isLoggedIn={isLoggedIn}
          accounts={accounts}
          onSelectAccount={this.onSelectAccount}
          clearSelectedAccount={this.clearSelectedAccount}
          selectedAccount={this.state.selectedAccount}
          selectedTransaction={selectedTransaction}
          selectNextTransaction={this.selectNextTransaction}
          loadingTransactions={this.state.loadingTransactions}
          uploading={this.state.uploading ? this.state.uploading : false}
          exportReceiptsToPDFs={
            transactions.length > 0 ? this.exportReceiptsToPDFs : null
          }
          transactions={transactions}
          onTransactionFileParsed={this.onTransactionFileParsed}
          onSelectTransaction={this.onSelectTransaction}
          setSelectedAccountDateRange={this.setSelectedAccountDateRange}
          searchingForEmail={this.state.searchingForEmail}
          searchForTransactionEmails={this.searchForTransactionEmails}
          config={this.state.config}
          emails={this.state.emails}
          onFreeTextEmailSearch={this.onFreeTextEmailSearch}
          onSelectEmail={this.onSelectEmail}
          onMarkEmail={this.onMarkEmail}
          markTransactionAsReadyForExport={this.markTransactionAsReadyForExport}
        >
          <InfoScreensHandler
            transactions={transactions}
            loadingTransactions={this.state.loadingTransactions}
            importingTransactionsFromApi={
              this.state.importingTransactionsFromApi
            }
            selectedTransaction={selectedTransaction}
            updateSelectedAccount={this.updateSelectedAccount}
            googleApiLoaded={this.state.googleApiLoaded}
            bankApiNeedsLogin={
              this.state.selectedAccount &&
              this.state.selectedAccount.bankApiNeedsLogin
                ? this.state.selectedAccount.bankApiNeedsLogin
                : false
            }
            onLogin={this.state.isLoggedIn ? null : this.onLogin}
            searchingForEmail={this.state.searchingForEmail}
            searchForTransactionEmails={this.searchForTransactionEmails}
            allTransactionsSearchedForEmails={
              this.state.allTransactionsSearchedForEmails
            }
            anySuggestedTransactions={this.state.anySuggestedTransactions}
            selectNextTransaction={this.selectNextTransaction}
            markTransactionAsReadyForExport={
              this.markTransactionAsReadyForExport
            }
            markAllSuggestionsAsReadyForExport={
              this.markAllSuggestionsAsReadyForExport
            }
          />
        </TransactionOverview>
      </main>
    )
  }

  render() {
    return (
      <div className="App__Wrapper">
        <HeaderBar onLogout={this.state.isLoggedIn ? this.onLogout : null} />
        <Switch>
          <Route exact path="/" component={this.Main} />
        </Switch>
      </div>
    )
  }
}

export default App
