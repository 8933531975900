import React from 'react'

import InfoView from '../../sharedComponents/InfoView'
import { Button, Icon } from 'antd'

const StartWork = props => {
  const transactions = props.transactions.filter(
    transaction => !transaction.readyForExport
  )

  const txWithoutEmails = transactions.filter(
    transaction =>
      !transaction.emailSearchResults ||
      Object.keys(transaction.emailSearchResults).length === 0
  )

  const transactionsWithEmailsCount =
    transactions.length - txWithoutEmails.length

  const ignoreAll = () => {
    // Set all transactions as readyForExport, this will ignore the remaining
    // as this module is only avaialbe when all transactions with suggestions are done
    transactions.forEach(transaction =>
      props.markTransactionAsReadyForExport(transaction.id, true)
    )
  }

  if (!transactions || transactions.length === 0) {
    return null
  }

  if (transactionsWithEmailsCount === 0) {
    // We have transactions, but no emails for them
    return (
      <InfoView
        text={`Not found emails for the remaining ${
          txWithoutEmails.length
        } transactions`}
        color="blue"
      >
        <Button onClick={props.selectNextTransaction} className="button">
          Go to first
        </Button>{' '}
        <Button onClick={ignoreAll} className="button">
          Ignore all <Icon type="cross" />
        </Button>
      </InfoView>
    )
  }

  return (
    <InfoView
      text={`System needs help choosing the right receipts for ${
        transactionsWithEmailsCount > 1
          ? `${transactionsWithEmailsCount} transactions`
          : `${transactionsWithEmailsCount} transaction`
      }`}
      color="blue"
    >
      <Button onClick={props.selectNextTransaction} className="button">
        Go to first
      </Button>
    </InfoView>
  )
}

export default StartWork
