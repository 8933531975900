// User.js, database commands for user space
import * as firebase from 'firebase/app'
import { firebaseSafeJson } from '../google/firebase'
import moment from '../util/moment'
import dbUtil from './util'
const { uniqueId, getAccountId } = dbUtil.transaction

const updatesForTransaction = (
  transaction,
  accountId,
  uid = firebase.auth().currentUser.uid,
  transactionId = transaction.id
) => {
  const updates = {}
  for (const key in transaction) {
    if (transaction.hasOwnProperty(key)) {
      key === 'date'
        ? (updates[
            `/user/${uid}/transactionList/${accountId}/${transactionId}/${key}`
          ] = transaction[key].format('x'))
        : (updates[
            `/user/${uid}/transactionList/${accountId}/${transactionId}/${key}`
          ] = transaction[key])
    }
  }
  return updates
}

async function createAll(
  transactions,
  accountId,
  uid = firebase.auth().currentUser.uid
) {
  if (!transactions || !accountId || !uid) return
  const updates = {}
  transactions.forEach(transaction => {
    transaction.accountId = accountId
    const transactionId = uniqueId(transaction)
    const updatesForThisTransaction = updatesForTransaction(
      transaction,
      accountId,
      uid,
      transactionId
    )
    const keysForThisUpdate = Object.keys(updatesForThisTransaction)
    keysForThisUpdate.forEach(key => {
      updates[key] = updatesForThisTransaction[key]
    })
    updates[
      `/user/${uid}/transactionList/${accountId}/${transactionId}/id`
    ] = transactionId
  })
  return firebase
    .database()
    .ref()
    .update(firebaseSafeJson(updates))
}

async function create(
  transaction,
  accountId,
  uid = firebase.auth().currentUser.uid
) {
  if (!transaction || !accountId || !uid) return
  const data = firebaseSafeJson({
    ...transaction,
    id: uniqueId(transaction),
    accountId
  })
  return firebase
    .database()
    .ref(`/user/${uid}/transactionList/${accountId}/${data.id}`)
    .set(data)
}

async function update(
  updateData,
  transactionId,
  accountId = getAccountId(transactionId),
  uid = firebase.auth().currentUser.uid
) {
  if (!updateData || !transactionId || !accountId || !uid) return
  const updates = updatesForTransaction(
    updateData,
    accountId,
    uid,
    transactionId
  )
  return firebase
    .database()
    .ref()
    .update(firebaseSafeJson(updates))
}

async function del(
  transactionId,
  accountId = getAccountId(transactionId),
  uid = firebase.auth().currentUser.uid
) {
  if (!transactionId || !accountId || !uid)
    // First delete any usedByTransaction in emails used by this transaction:
    await firebase
      .database()
      .ref(
        `/user/${uid}/transactionList/${accountId}/${transactionId}/emailSearchResults`
      )
      .once('value', emails => {
        emails.forEach(email => {
          email.ref(`usedByTransaction`).once('value', usedByTransaction => {
            if (usedByTransaction === transactionId) {
              usedByTransaction.ref.remove()
            }
          })
        })
      })
  return firebase
    .database()
    .ref(`/user/${uid}/transactionList/${accountId}/${transactionId}`)
    .remove()
}

async function listen(
  accountId,
  uid = firebase.auth().currentUser.uid,
  handleTransactions = input => null,
  startDate = moment().subtract(1, 'months'),
  endDate = moment(),
  finishedInitialLoad = input => null
) {
  await stopListen(accountId, uid)
  // startDate and endDate are moment Date-objects
  if (!uid || !accountId || !startDate || !endDate) return

  const listen = firebase
    .database()
    .ref(`/user/${uid}/transactionList/${accountId}`)
    .orderByChild('dateTime/utc')
    .startAt(startDate.format('x'))
    .endAt(endDate.format('x'))

  listen.on('value', snapshot => {
    const transactions = snapshot.val()
      ? Object.values(snapshot.val()).map(transaction => ({
          emailSearchResults: {},
          ...transaction,
          date: moment(transaction.dateTime.utc, 'x').tz(
            transaction.dateTime.timezone
          )
        }))
      : []
    handleTransactions(transactions)
  })

  return listen.once('value', snapshot => {
    const transactions = snapshot.val()
    finishedInitialLoad(transactions)
  })
}

async function stopListen(accountId, uid = firebase.auth().currentUser.uid) {
  if (!uid || !accountId) return
  return firebase
    .database()
    .ref(`/user/${uid}/transactionList/${accountId}`)
    .off()
}

const transaction = { createAll, create, update, del, listen, stopListen }

export default transaction
