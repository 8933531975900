export function createScoreTracker(transaction, searchFunction) {
  const scores = {}
  return async function searchAndBump(queryCreator, score) {
    const queryString = queryCreator(transaction)
    const results = (await searchFunction(queryString)) || []
    results.forEach(result => {
      scores[result.id] = (scores[result.id] || 0) + score
    })
    return { scores, results }
  }
}

export function sortScores(scores) {
  return Object.keys(scores)
    .map(key => ({ id: key, score: scores[key] }))
    .sort((itemA, itemB) => itemB.score - itemA.score)
}

export function getScoresAtOrHigher(scores, point) {
  return Object.keys(scores)
    .map(key => ({ id: key, score: scores[key] }))
    .filter(item => item.score >= point)
}
