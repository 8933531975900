import request from 'request-promise-native'
import { trimTransactionData } from '../domain/transaction'
import config from '../config'
import { message } from 'antd'

export async function startNewJob(options = {}) {
  try {
    const { uuid } = await request(`${config.apiUri}/api/converter/start`, {
      method: 'POST',
      json: true,
      body: options
    })
    return uuid
  } catch (error) {
    message.error(`Couldn't contact the server. Please try again :(`)
    return false
  }
}

export default async function(uuid, transaction) {
  try {
    const trimmedTransaction = trimTransactionData(transaction)
    const result = await request({
      method: 'POST',
      uri: `${config.apiUri}/api/converter/${uuid}`,
      body: trimmedTransaction,
      json: true
    })
    return result
  } catch (error) {
    message.error(`Couldn't upload transaction to server. Please try again :(`)
    return false
  }
}

export async function finishJob(uuid) {
  try {
    const { url } = await request(
      `${config.apiUri}/api/converter/${uuid}/finish`,
      {
        method: 'POST',
        json: true
      }
    )
    return url
  } catch (error) {
    message.error(`Couldn't download file from Server. Please try again :(`)
    return false
  }
}
