import { Button, Layout } from 'antd'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

import { login } from '../../util/auth'
import './styles.css'

class LoginForm extends PureComponent {
  onClick = async () => {
    const user = await login()
    this.props.onLogin(user)
  }

  render(props) {
    return (
      <Layout className="loginForm">
        <Button
          size="large"
          className="loginForm__button"
          onClick={this.onClick}
          icon="mail"
        >
          Log in through Gmail
        </Button>
      </Layout>
    )
  }
}

LoginForm.propTypes = {
  onLogin: PropTypes.func
}

export default LoginForm
