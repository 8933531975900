// Generates a hopefully unique and one-way encrypted ID
// so we can update the RIGHT transaction in firebase
// Reason for not using FBs own unique IDs is so we can update the right tx without searching for it

// Takes the following arguments:

// stringArray - array of strings, such as:
// arkivreferanse (from csv)
// account # (from csv)
// dateTime or more

// hashes it all, adds an optional preString (before hash) and returns

import crypto from 'crypto'
import URLSafeBase64 from 'urlsafe-base64'
import { firebaseSafeRegex } from '../google/firebase'

const generateId = (dataArray, preString = '', postString = '') => {
  const dataString = dataArray.sort().join()
  if (dataString.length > 0) {
    const uniqueId = URLSafeBase64.encode(
      crypto
        .createHash('sha256')
        .update(dataString)
        .digest('base64')
    )
    return `${preString}${uniqueId}${postString}`.replace(firebaseSafeRegex, '')
  } else {
    return `${preString}${postString}`.replace(firebaseSafeRegex, '')
  }
}

export default generateId
