import { Button, Layout, Icon } from 'antd'
import React from 'react'
import propTypes from 'prop-types'

import './styles.css'

const { Header } = Layout

function createLogoutButton(props) {
  return props.onLogout ? (
    <Button
      key="logoutButton"
      className="header__button"
      onClick={props.onLogout}
    >
      Log out <Icon type="logout" />
    </Button>
  ) : null
}

export default function HeaderBar(props) {
  return (
    <Header className="header">
      <h1>Receipt Runner</h1>
      {createLogoutButton(props)}
    </Header>
  )
}

Header.propTypes = {
  exportReceiptsToPDFs: propTypes.func,
  onLogout: propTypes.func
}
