import iconvlite from 'iconv-lite'
import { parse as parseContentType } from 'content-type'

const mimeTypes = {
  mixed: 'multipart/mixed',
  alternative: 'multipart/alternative',
  html: 'text/html',
  plain: 'text/plain'
}

function getCharacterSet(headers, fallback) {
  try {
    const parseResult = parseContentType(
      headers.find(header => header.name.toLowerCase() === 'content-type').value
    )
    return parseResult.parameters.charset
      ? parseResult.parameters.charset
      : fallback
  } catch (error) {
    return fallback
  }
}

function mapPartContent({ body, headers }, type) {
  const charSet = getCharacterSet(headers, 'UTF-8')
  if (!body || !body.data || !body.data instanceof String) {
    return { content: '' }
  }
  const cleanedBody = body.data.replace(/-/g, '+').replace(/_/g, '/')
  const parsedBody = atob(cleanedBody)
  return { content: iconvlite.decode(parsedBody, charSet), [type]: type }
}

function mapHtmlPart(part) {
  return mapPartContent(part, 'html')
}

function mapTextPart(part) {
  return mapPartContent(part, 'text')
}

function mapAttachmentPart({ body, fileName, mimeType }) {
  if (!body.attachmentId) {
    return undefined
  }
  return {
    size: body.size,
    attachmentId: body.attachmentId,
    fileName,
    mimeType
  }
}

export function parsePayloadParts(payload) {
  if (payload.mimeType === mimeTypes.html) {
    return [mapHtmlPart(payload)]
  }
  if (payload.mimeType === mimeTypes.plain) {
    return [mapTextPart(payload)]
  }
  if (!payload.parts) {
    return []
  }
  return payload.parts
    .map(part => {
      switch (part.mimeType) {
        case mimeTypes.mixed:
          return parsePayloadParts(part)
        case mimeTypes.alternative:
          return parsePayloadParts(part)
        case mimeTypes.html:
          return mapHtmlPart(part)
        case mimeTypes.plain:
          return mapTextPart(part)
        default:
          return mapAttachmentPart(part)
      }
    })
    .filter(Boolean)
    .reduce(
      (result, part) =>
        Array.isArray(part) ? [...result, ...part] : [...result, part],
      []
    )
}

export function parsePayloadHeaders(payload) {
  return payload.headers
    .filter(header => ['Subject', 'To', 'From'].indexOf(header.name) !== -1)
    .reduce(
      (result, header) => ({
        ...result,
        [header.name.toLowerCase()]: header.value
      }),
      {}
    )
}
