import React from 'react'

import InfoView from '../../sharedComponents/InfoView'
import { Button, Icon } from 'antd'
import { hasSelectedEmail } from '../../domain/transaction'

const ApproveAllSuggestions = props => {
  const transactionsWithSuggestions = props.transactions.filter(
    transaction => !transaction.readyForExport && hasSelectedEmail(transaction)
  )

  return (
    <InfoView
      text={
        props.text
          ? props.text
          : `System has suggested a receipt for ${
              transactionsWithSuggestions.length
            } ${
              transactionsWithSuggestions.length > 1
                ? 'transactions'
                : 'transaction'
            }`
      }
      color={props.color ? props.color : 'blue'}
    >
      <Button
        onClick={props.markAllSuggestionsAsReadyForExport}
        className="button button--blue"
      >
        Approve all <Icon type="check" />
      </Button>{' '}
      <Button onClick={props.selectNextTransaction} className="button">
        Go to first
      </Button>
    </InfoView>
  )
}

export default ApproveAllSuggestions
