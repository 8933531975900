import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import KeyboardEventHandler from 'react-keyboard-event-handler'

import { Layout } from 'antd'
import TransactionList from './TransactionList'
import TransactionListHeader from './TransactionListHeader'
import AccountChooser from './AccountChooser'
import AccountAdder from './AccountAdder'

import './styles.css'

class TransactionOverview extends PureComponent {
  render() {
    const {
      // Config:
      config,
      isLoggedIn,

      // Account stuff:
      accounts,
      clearSelectedAccount,
      onSelectAccount,
      selectedAccount,
      setSelectedAccountDateRange,

      // Transactions:
      transactions,
      loadingTransactions,
      selectedTransaction,
      onSelectTransaction,
      exportReceiptsToPDFs,
      selectNextTransaction,
      uploading,
      markTransactionAsReadyForExport,

      // Email stuff
      emails,
      onFreeTextEmailSearch,
      searchingForEmail,
      searchForTransactionEmails,
      onSelectEmail,
      onMarkEmail,

      // CSV handling:
      onTransactionFileParsed
    } = this.props

    if (!isLoggedIn) {
      return (
        <Layout className="transactionOverview--notLoggedIn">
          {this.props.children}
        </Layout>
      )
    }

    if (this.props.selectedAccount) {
      const nextKey = 'd'
      const nextAction = () => {
        selectNextTransaction()
      }
      const previousKey = 'a'
      const previousAction = () => {
        selectNextTransaction(false, true)
      }
      const nextUnfinishedKey = 's'
      const nextUnfinishedAction = () => {
        selectNextTransaction(true)
      }
      const previousUnfinishedKey = 'w'
      const previousUnfinishedAction = () => {
        selectNextTransaction(true, true)
      }

      const handleKeyShortcuts = (key, event) => {
        // Ignore if in text or textarea type inputs:
        if (
          (event.target.type === 'text' && event.target.tagName === 'INPUT') ||
          event.target.tagName === 'TEXTAREA'
        ) {
          return
        }

        switch (key) {
          case nextKey:
            nextAction()
            break
          case previousKey:
            previousAction()
            break
          case nextUnfinishedKey:
            nextUnfinishedAction()
            break
          case previousUnfinishedKey:
            previousUnfinishedAction()
            break
          default:
        }
      }

      return (
        <Layout
          className={
            selectedTransaction
              ? 'transactionOverview--transactionSelected'
              : 'transactionOverview'
          }
        >
          <KeyboardEventHandler
            handleKeys={[
              nextKey,
              previousKey,
              nextUnfinishedKey,
              previousUnfinishedKey
            ]}
            onKeyEvent={handleKeyShortcuts}
            handleFocusableElements={true}
          />

          <TransactionListHeader
            clearSelectedAccount={clearSelectedAccount}
            onSelectAccount={onSelectAccount}
            selectedAccount={selectedAccount}
            setSelectedAccountDateRange={setSelectedAccountDateRange}
            searchingForEmail={searchingForEmail}
            transactions={transactions}
            exportReceiptsToPDFs={
              transactions.length > 0 ? exportReceiptsToPDFs : null
            }
            uploading={uploading ? uploading : false}
          />
          <div className="transactinListInfoviews">{this.props.children}</div>
          <TransactionList
            emails={emails}
            onFreeTextEmailSearch={onFreeTextEmailSearch}
            onSelectEmail={onSelectEmail}
            onMarkEmail={onMarkEmail}
            markTransactionAsReadyForExport={markTransactionAsReadyForExport}
            loadingTransactions={loadingTransactions}
            transactions={transactions}
            searchingForEmail={searchingForEmail}
            searchForTransactionEmails={searchForTransactionEmails}
            selectedTransaction={selectedTransaction}
            onSelectTransaction={onSelectTransaction}
            selectNextTransaction={selectNextTransaction}
            uploading={uploading ? uploading : false}
            config={config}
          />
        </Layout>
      )
    }
    return (
      <Layout className="accountChooser">
        <Layout className="centeredView">
          <h2>Accounts:</h2>
          <AccountChooser
            accounts={accounts}
            onSelectAccount={onSelectAccount}
          />
          <AccountAdder onTransactionFileParsed={onTransactionFileParsed} />
        </Layout>
      </Layout>
    )
  }
}

TransactionOverview.propTypes = {
  onTransactionFileParsed: PropTypes.func,
  fileName: PropTypes.string,
  transactions: PropTypes.arrayOf(PropTypes.object),
  selectedTransaction: PropTypes.object,
  onSelectTransaction: PropTypes.func
}

export default TransactionOverview
