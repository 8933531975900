import React from 'react'
import PlaidConnectButton from '../bankAccounts/PlaidConnectButton'
import { Icon } from 'antd'
import InfoView from '../../sharedComponents/InfoView'

const BankApiNeedsLogin = props => {
  const api = props.api
  const options = props.options
  const updateSelectedAccount = props.updateSelectedAccount
  // Which bank API needs login?

  switch (api) {
    case 'plaid':
      return (
        <InfoView
          text="Couldn't auto-connect to your bank. Login again?"
          color="red"
        >
          <PlaidConnectButton
            updateSelectedAccount={updateSelectedAccount}
            public_token={
              options & options.public_token ? options.public_token : null
            }
          >
            Login <Icon type="bank" />
          </PlaidConnectButton>
        </InfoView>
      )
    default:
      // wtf we don't know what bank api this is...
      return null
  }
}

export default BankApiNeedsLogin
