import React from 'react'
import PlaidConnectButton from '../bankAccounts/PlaidConnectButton'
import FileUpload from './FileUpload'
import { Icon } from 'antd'

const AccountAdder = props => (
  <div id="accountAdder">
    <PlaidConnectButton>
      Add US or CA accounts <Icon type="bank" />
    </PlaidConnectButton>
    <FileUpload onTransactionFileParsed={props.onTransactionFileParsed} />
  </div>
)

export default AccountAdder
