import csvtojson from 'csvtojson'

const parseCSV = (mapFunction, headers, cleanerFunction = csv => csv) => async (
  csvString,
  delimiter = ';'
) => {
  const cleanedCSV = cleanerFunction(csvString)
  const arrayOfParsedJSON = await csvtojson({
    delimiter,
    headers,
    noheader: true
  }).fromString(cleanedCSV)
  return arrayOfParsedJSON.map(mapFunction).filter(Boolean)
}

export default parseCSV
