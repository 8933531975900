import {
  createQueryForAttachments,
  createQueryForReceiptTerms,
  createQueryForReceiptTermsInSubject,
  createQueryFromCurrency,
  createQueryFromCurrencyAndSum,
  createQueryFromDescription,
  createQueryFromDescriptionInSubject,
  createQueryFromMerchant,
  createQueryFromMerchantInSubject,
  createQueryFromSum,
  createQueryFromTransaction
} from '../presentation/gmail'
import {
  createScoreTracker,
  getScoresAtOrHigher,
  sortScores
} from './emailScoring'
import {
  getMessageDetails,
  rawGmailSearch,
  searchForEmail,
  getAttachmentData
} from '../util/gmailSearch'
import b64toBlob from 'b64-to-blob'
import { saveAs } from 'file-saver'

function transactionStillInState(transaction, index, stateTransactions) {
  const transactionId = transaction.id ? transaction.id : 'no tx id'
  const stateTransactionId = stateTransactions[index]
    ? stateTransactions[index].id
    : 'no state tx id'
  return transactionId === stateTransactionId
}

export async function searchForTransaction(transaction, index = false) {
  if (!index) {
    // if index isn't set, check for transaction index
    index = this.state.transactions.indexOf(transaction)
  }

  // Check if tx is still relevant, if not, return:
  if (!transactionStillInState(transaction, index, this.state.transactions))
    return

  const score = {
    merchantInSender: 10, // Checks if sender is merchant
    merchantInSubject: 5,
    receiptSender: 3, // For checking if sender is Stripe, et.al. TODO: Not currently in use
    currencyAndSum: 5,
    currency: 2,
    sum: 2, // Score MUCH higher for complicated sums?
    receiptTerms: 2,
    receiptTermsInSubject: 3, // 3x when merchant name is found
    description: 1,
    descriptionInSubject: 2,
    attachments: 1,
    minScore: 6, // Min score to not be cut off
    betterThanCutOff: 5 // How much higher than the next score should a score be to cut off the rest?
  }

  const metaInformation = {
    merchantNameFound: false,
    receiptTermsFound: false
  }

  const emailSearchQuery = createQueryFromTransaction(transaction)
  const emailSearchResults = await searchForEmail(emailSearchQuery)

  // Check if tx is still relevant, if not, return:
  if (!transactionStillInState(transaction, index, this.state.transactions))
    return

  if (emailSearchResults.length > 0 && emailSearchResults.length < 4) {
    return {
      emailSearchQuery,
      emailSearchResults:
        emailSearchResults.length === 1
          ? [
              {
                ...emailSearchResults[0],
                attachments: await fetchAttachments(emailSearchResults[0]),
                selected: true
              }
            ]
          : emailSearchResults,
      perfectMatch: true,
      matchedBy: 'Full match'
    }
  }
  const searchAndBump = createScoreTracker(transaction, rawGmailSearch)

  // Check if tx is still relevant, if not, return:
  if (!transactionStillInState(transaction, index, this.state.transactions))
    return

  const merchantInSender = await searchAndBump(
    createQueryFromMerchant,
    score.merchantInSender
  )
  if (merchantInSender.results.length === 0) {
    const merchantInSubject = await searchAndBump(
      createQueryFromMerchantInSubject,
      score.merchantInSubject
    )
    if (merchantInSubject.results.length !== 0) {
      metaInformation.merchantNameFound = true
    }
  } else {
    metaInformation.merchantNameFound = true
  }

  const { results: resultsForCurrencyAndSum } = await searchAndBump(
    createQueryFromCurrencyAndSum,
    score.currencyAndSum
  )

  if (resultsForCurrencyAndSum.length === 0) {
    // Check if tx is still relevant, if not, return:
    if (!transactionStillInState(transaction, index, this.state.transactions))
      return

    await searchAndBump(createQueryFromCurrency, score.currency)

    // Check if tx is still relevant, if not, return:
    if (!transactionStillInState(transaction, index, this.state.transactions))
      return

    await searchAndBump(createQueryFromSum, score.sum)
  }

  if (!transactionStillInState(transaction, index, this.state.transactions))
    return
  const {
    results: receiptTermResults,
    scores: tempScores
  } = await searchAndBump(
    createQueryForReceiptTerms,
    metaInformation.merchantNameFound
      ? score.receiptTerms * 3
      : score.receiptTerms
  )

  if (receiptTermResults.length !== 0) {
    metaInformation.receiptTermsFound = true
  }

  if (!transactionStillInState(transaction, index, this.state.transactions))
    return
  const perfectScore =
    score.merchantInSubject + score.currencyAndSum + score.receiptTermsInSubject
  const perfectScorers = getScoresAtOrHigher(tempScores, perfectScore)

  if (perfectScorers.length === 1) {
    const emailSearchResult = await getMessageDetails(perfectScorers[0])
    return {
      emailSearchQuery: emailSearchQuery,
      emailSearchResults: [
        {
          ...emailSearchResult,
          attachments: await fetchAttachments(emailSearchResult),
          selected: true
        }
      ],
      perfectMatch: true,
      matchedBy: 'GetScoresAtOrHigher above Perfect Treshold, and only one'
    }
  }

  if (!transactionStillInState(transaction, index, this.state.transactions))
    return

  const descriptionInSubject = await searchAndBump(
    createQueryFromDescriptionInSubject,
    score.descriptionInSubject
  )
  if (descriptionInSubject.results.length === 0) {
    if (!transactionStillInState(transaction, index, this.state.transactions))
      return
    await searchAndBump(createQueryFromDescription, score.description)
  }

  if (!transactionStillInState(transaction, index, this.state.transactions))
    return
  await searchAndBump(
    createQueryForReceiptTermsInSubject,
    metaInformation.merchantNameFound
      ? score.receiptTermsInSubject * 3
      : metaInformation.receiptTermsFound
      ? score.receiptTermsInSubject - score.receiptTerms
      : score.receiptTermsInSubject
  )

  if (!transactionStillInState(transaction, index, this.state.transactions))
    return
  const { scores } = await searchAndBump(
    createQueryForAttachments,
    score.attachments
  )

  const sortedScores = sortScores(scores)
    .slice(0, 4)
    .filter((item, index, array) =>
      index > 0
        ? item.score > score.minScore &&
          item.score + score.betterThanCutOff > array[0].score
        : item.score > score.minScore
    )

  const firstIsBest =
    sortedScores.length > 1 &&
    sortedScores[0].score > sortedScores[1].score + score.betterThanCutOff
  const messageDetailPromises = firstIsBest
    ? [getMessageDetails(sortedScores[0])]
    : sortedScores.map(sortedScore => getMessageDetails(sortedScore))
  if (!transactionStillInState(transaction, index, this.state.transactions))
    return
  const emailSearchResultsPromisesResults = await Promise.all(
    messageDetailPromises
  )
  return {
    emailSearchQuery: emailSearchQuery,
    emailSearchResults: emailSearchResultsPromisesResults
  }
}

export async function fetchAttachments(email) {
  if (!email.attachments || email.attachments.length === 0) return []
  const promises = email.attachments.map(async attachment => {
    const { result } = await getAttachmentData(
      email.id,
      attachment.attachmentId
    )
    const { data, size } = result
    return { ...attachment, data, size }
  })
  return await Promise.all(promises)
}

export async function decodeAttachments(email) {
  const attachmentData = await fetchAttachments(email)
  const decodedAttachmentData = attachmentData.map(
    attachment =>
      b64toBlob(
        attachment.data.replace(/-/g, '+').replace(/_/g, '/'),
        attachment.mimeType
      ) // Replaces are unique to Gmail, who decode their base64 wrongly for some reason
  )
  return decodedAttachmentData
}

export async function downloadAttachmentsDirectly(email) {
  const attachmentData = await decodeAttachments(email)
  attachmentData.map(attachment => {
    const filename = encodeURIComponent(
      email.subject.replace(/ /g, '_').replace(/\./g, '_')
    )
    return saveAs(attachment, filename)
  })
}

export async function searchForText(emailSearchQuery) {
  const emailSearchResults = await searchForEmail(emailSearchQuery)
  return {
    emailSearchResults,
    emailSearchQuery
  }
}
