import React from 'react'

import InfoView from '../../sharedComponents/InfoView'

const NoTransactions = props => (
  <InfoView
    text="No transactions found for this date range."
    icon="file-text"
  />
)

export default NoTransactions
