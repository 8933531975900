import React from 'react'
import { Button, Icon } from 'antd'

import InfoView from '../../sharedComponents/InfoView'

const CurrentlySearchingForEmails = props => (
  <InfoView text="Searching through emails!" icon="loading" />
)

const StartSearchForEmails = props => (
  <InfoView
    text={props.text ? props.text : `Ready to search for email receipts?`}
    color={props.color ? props.color : 'blue'}
  >
    <Button
      onClick={
        props.transaction
          ? () => props.searchForTransactionEmails(props.transaction)
          : () => props.searchForTransactionEmails()
      }
      className="button button--blue"
      loading={props.searchingForEmail}
    >
      Run receipts <Icon type="forward" />
    </Button>
  </InfoView>
)

const SomeTransactionsMissingEmailSearch = props => (
  <InfoView
    text={
      props.text
        ? props.text
        : `Haven't searched for emails for all transactions.`
    }
    color={props.color ? props.color : 'blue'}
  >
    <Button
      onClick={
        props.transaction
          ? () => props.searchForTransactionEmails(props.transaction)
          : () => props.searchForTransactionEmails()
      }
      className="button button--blue"
      loading={props.searchingForEmail}
    >
      Run receipts <Icon type="forward" />
    </Button>
  </InfoView>
)

export {
  StartSearchForEmails,
  CurrentlySearchingForEmails,
  SomeTransactionsMissingEmailSearch
}
