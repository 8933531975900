import React, { PureComponent } from 'react'
import { Button } from 'antd'

class TransactionNavigation extends PureComponent {
  render() {
    const {
      // Props:
      selectNextTransaction
    } = this.props

    return (
      <nav id="transactionNavigation">
        <h2 className="transactionNavigation__header">
          Finished with this transaction?
        </h2>
        <ul className="transactionNavigation__actions">
          <li key="transactionNavigation__action--previous">
            <Button
              onClick={() => selectNextTransaction(true, true)}
              className="button button--grey"
            >
              Previous unfinished transaction [w]
            </Button>
          </li>
          <li key="transactionNavigation__action--next">
            <Button
              onClick={() => selectNextTransaction(true)}
              className="button button--grey"
            >
              Next unfinished transaction [s]
            </Button>
          </li>
        </ul>
      </nav>
    )
  }
}

export default TransactionNavigation
