import { Button, Input, Icon, Modal, message } from 'antd'
import './styles.css'

import React from 'react'
import database from '../../database'

const InputGroup = Input.Group

export default class ExportModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      emailToSendReceiptsTo: false,
      visible: false
    }
    this.handleChange = this.handleChange.bind(this)
  }

  showModal = () => {
    this.setState({
      visible: true,
      emailToSendReceiptsTo: this.props.emailToSendReceiptsTo
        ? this.props.emailToSendReceiptsTo
        : false
    })
  }

  closeModal = () => {
    this.setState({
      visible: false,
      emailToSendReceiptsTo: false
    })
  }

  handleChange(event) {
    this.setState({
      emailToSendReceiptsTo: event.target.value ? event.target.value : ''
    })
  }

  sendByEmail = async () => {
    const emailToSendReceiptsTo = this.state.emailToSendReceiptsTo
    if (/^.+@.+\..+$/.test(emailToSendReceiptsTo)) {
      // Store emailToSenReceiptsTo:
      database.user.config({
        emailToSendReceiptsTo: emailToSendReceiptsTo
      })

      // Then send emails
      await this.props.exportReceiptsToPDFs({
        send: {
          type: 'email',
          to: emailToSendReceiptsTo
        }
      })
      this.closeModal()
    } else {
      message.error('Not a valid email?')
    }
  }

  render() {
    const props = this.props
    const emailToSendReceiptsTo = this.state.emailToSendReceiptsTo

    return (
      <span>
        <Button
          className={
            props.readyCount === props.transactionCount
              ? 'button--blue'
              : 'button'
          }
          key="exportButton"
          onClick={this.showModal}
          loading={props.uploading}
          disabled={!props.readyCount}
        >
          Export <Icon type="rocket" />
        </Button>
        <Modal
          title={null}
          visible={this.state.visible}
          onOk={this.closeModal}
          footer={
            <Button key="back" onClick={this.closeModal}>
              Cancel
            </Button>
          }
          confirmLoading={props.uploading}
          onCancel={this.closeModal}
          cancelText="Avbryt"
          closable={false}
        >
          <h2>Export</h2>
          {props.readyCount === props.transactionCount ? (
            <p>All finished</p>
          ) : (
            <p>
              {props.readyCount} / {props.transactionCount} finished
            </p>
          )}
          <h3>Send to email</h3>
          <InputGroup>
            <Input
              type="email"
              id="emailToSendReceiptsTo"
              placeholder="receipts-go-here@your-accountant.com"
              value={emailToSendReceiptsTo}
              onChange={this.handleChange}
            />
            <Button
              key="emailButton"
              icon="mail"
              onClick={this.sendByEmail}
              loading={props.uploading}
              type="primary"
            >
              Send to email
            </Button>
          </InputGroup>
          <h3>Download PDFs</h3>
          <InputGroup>
            <Button
              key="uploadButton"
              icon="file-pdf"
              onClick={async () => {
                props.exportReceiptsToPDFs({
                  send: {
                    type: 'download'
                  }
                })
                this.closeModal()
              }}
              loading={props.uploading}
              type="primary"
            >
              Download
            </Button>
          </InputGroup>
        </Modal>
      </span>
    )
  }
}
