import Moment from 'moment-timezone'
//import 'moment/locale/nb' <-- When we do have translations, we might need this. For now its all US english.
import { extendMoment } from 'moment-range'
const moment = extendMoment(Moment)
export default moment

// Date range functions

export const combineDateRanges = (ranges = []) => {
  // Combines a set of dateRanges into an array of non-overlapping moment ranges

  // moment ranges have the following structure: {start, end}

  // Then sort the array, so ranges are sorted by start date.
  // Then compare them and combine them one by one:
  // Take the first range, and add to the next.
  //     If it doesn't work, add the range to the newRanges array.
  //     If it does work, take the combined array and try adding to the next, until it doesn't work or we run out of ranges.

  const sortedRanges = ranges.sort(
    (first, second) => first.start.valueOf() - second.end.valueOf()
  )
  const combinedRanges = []
  let indexesAdded = 0
  sortedRanges.forEach((range, index, array) => {
    if (index < indexesAdded) return
    if (!array[index + 1]) {
      combinedRanges.push(range)
      return
    }
    let combinedRange = range
    let step = 0
    while (combinedRange) {
      const newCombinedRange = array[index + 1 + step]
        ? combinedRange.add(array[index + 1 + step], { adjacent: true })
        : false
      if (!newCombinedRange) {
        combinedRanges.push(combinedRange)
      }
      combinedRange = newCombinedRange
      step++
    }

    indexesAdded = index + step
  })
  return combinedRanges
}

export const rangeIsCovered = (newRange, ranges) => {
  // Returns boolean showing if daterange is covered within an array of ranges
  return !!ranges.find(
    range => newRange.start.within(range) && newRange.end.within(range)
  )
}
