import { Button, DatePicker, Layout, Icon } from 'antd'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import moment from '../../util/moment'
import capitalize from '../../util/capitalize'
import ExportModal from '../exportModal'

const { RangePicker } = DatePicker

const suggestedRanges = (numberOfPeriodsToSuggest = 3) => {
  const currentMonthNumber = moment().format('M')
  const ranges = {}

  ranges[
    `All of ${moment()
      .subtract(1, 'years')
      .format('YYYY')}`
  ] = [
    moment()
      .subtract(1, 'years')
      .startOf('year'),
    moment()
      .subtract(1, 'years')
      .endOf('year')
  ]

  ranges[`All of ${moment().format('YYYY')}`] = [
    moment().startOf('year'),
    moment()
  ]

  const firstEndOfPeriod = currentMonthNumber % 2 ? 0 : -1
  // first range is start of currentMonth - 2 months, to end of currentMonth -1
  // second range is currentMonth - 4 months, to end of currentMonth -3
  for (let i = numberOfPeriodsToSuggest; i >= 1; i--) {
    const startOfPeriod = moment()
      .subtract(firstEndOfPeriod + i * 2, 'months')
      .startOf('month')
    const endOfPeriod = moment()
      .subtract(firstEndOfPeriod - 1 + i * 2, 'months')
      .endOf('month')
    const nameOfPeriod = capitalize(
      `${startOfPeriod.format('MMM')}-${endOfPeriod.format(
        'MMM'
      )} ${endOfPeriod.format('YYYY')}`
    )
    ranges[nameOfPeriod] = [startOfPeriod, endOfPeriod]
  }
  return ranges
}

class TransactionListHeader extends PureComponent {
  constructor() {
    super()
    this.state = { openRangePicker: false }
  }

  toggleRangePicker = () => {
    this.setState({ openRangePicker: !this.state.openRangePicker })
  }

  render() {
    const {
      transactions,
      exportReceiptsToPDFs,
      uploading,
      config,
      selectedAccount,
      clearSelectedAccount,
      setSelectedAccountDateRange
    } = this.props

    const { openRangePicker } = this.state

    const transactionCount = transactions.length
    const readyCount = transactions.filter(
      transaction => transaction.readyForExport
    ).length

    const transactionStatus = transactionCount ? (
      <div id="export_transactions">
        <span className="export_transactions--status">{`${readyCount} / ${transactionCount} ready`}</span>
        <ExportModal
          readyCount={readyCount}
          transactionCount={transactionCount}
          exportReceiptsToPDFs={exportReceiptsToPDFs}
          uploading={uploading}
          emailToSendReceiptsTo={config ? config.emailToSendReceiptsTo : ''}
        />
      </div>
    ) : null

    const startDate = selectedAccount.startDate
      ? selectedAccount.startDate
      : false
    const endDate = selectedAccount.endDate ? selectedAccount.endDate : false

    return (
      <Layout className="transactionListHeader">
        <nav className="transactionListHeader__breadcrumb">
          <Button
            onClick={() => clearSelectedAccount()}
            className="account__backToOtherAccounts button button--text"
          >
            <Icon type="arrow-left" /> To your accounts
          </Button>
        </nav>
        <header className="transactionListHeader__content">
          <div className="transactionListHeader__account">
            <h2 className="account">
              <span className="account__name">{selectedAccount.name}</span>
              <span className="account__formalInformation">
                {selectedAccount.bank} {selectedAccount.number}
              </span>
            </h2>
            {openRangePicker ? (
              <RangePicker
                className="rangepicker"
                open={openRangePicker}
                onOpenChange={status =>
                  !status ? this.toggleRangePicker() : null
                }
                defaultValue={[startDate, endDate]}
                onChange={setSelectedAccountDateRange}
                onOk={this.toggleRangePicker}
                placeholder={['This year', '']}
                format={'ll'}
                ranges={suggestedRanges()}
                size="1"
              />
            ) : (
              <Button
                onClick={() => this.toggleRangePicker()}
                className="rangepicker__button button button--text"
                title="Fetch transactions for date range"
              >
                {startDate.format('ll')} - {endDate.format('ll')}
                <Icon type="calendar" />
              </Button>
            )}
          </div>
          <div className="transactionListHeader__export">
            {transactionStatus}
          </div>
        </header>
      </Layout>
    )
  }
}

TransactionListHeader.propTypes = {
  selectedAccount: PropTypes.object,
  clearSelectedAccount: PropTypes.func,
  onSelectAccount: PropTypes.func
}

export default TransactionListHeader
