import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'

import * as firebase from 'firebase/app'

import { googleConfig } from '../config'

export async function initializeFirebase() {
  await firebase.initializeApp(googleConfig)
}

export async function firebaseLogin(idToken) {
  const creds = firebase.auth.GoogleAuthProvider.credential(idToken)
  const { user } = await firebase
    .auth()
    .signInAndRetrieveDataWithCredential(creds)
  return user
}

export async function firebaseLogout() {
  await firebase.auth().signOut()
}

export const firebaseSafeRegex = /[.#$/[\]]+/g

export const firebaseSafeJson = json => {
  // Removes all undefined with null
  return JSON.parse(
    JSON.stringify(json, (_, value) => (value === undefined ? null : value))
  )
}
