import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import registerServiceWorker from './registerServiceWorker'
import { LocaleProvider } from 'antd'
import nb_NO from 'antd/lib/locale-provider/nb_NO'
import { BrowserRouter } from 'react-router-dom'

ReactDOM.render(
  <LocaleProvider locale={nb_NO}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </LocaleProvider>,
  document.getElementById('root')
)
registerServiceWorker()
