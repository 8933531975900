import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

import {
  transactionPropType,
  hasSelectedEmail
} from '../../../domain/transaction'
import {
  StartSearchForEmails,
  CurrentlySearchingForEmails
} from '../../infoScreens/SearchingForEmails'
import { Layout } from 'antd'
const { Header } = Layout

class EmailListHeader extends PureComponent {
  getEmailListHeaderText = transaction => {
    const emailSelected = hasSelectedEmail(transaction)
    if (!emailSelected && transaction.readyForExport) {
      // Ignored tx
      return null
    }

    if (this.props.searchingForEmail) {
      return <CurrentlySearchingForEmails />
    }
    const emailSearched = transaction.searchedForEmails
    const startSearchForEmails = (
      <StartSearchForEmails
        text="Haven't searched for receipt in emails"
        color="red"
        transaction={transaction}
        searchForTransactionEmails={this.props.searchForTransactionEmails}
        searchingForEmail={this.props.searchingForEmail}
      />
    )
    if (!emailSearched) {
      return startSearchForEmails
    }
    const emailSearchCount = Object.keys(transaction.emailSearchResults).length

    if (emailSelected && transaction.readyForExport) {
      return 'Chosen receipt:'
    }

    if (emailSearchCount === 0) {
      return `Didn't find receipt in email.`
    }

    if (emailSelected) {
      return `We think this is the right receipt. Is it?`
    }

    if (emailSearchCount > 1) {
      return `Are any of these emails the right receipt?`
    }
    if (emailSearchCount === 1) {
      return `Is this email the right receipt?`
    }
    // Should never get here
    return `Somethings off. Perhaps you want to search for receipts again? ${startSearchForEmails}`
  }

  render() {
    const { transaction } = this.props
    return (
      <Header className="Header">
        <div className="transactionData">
          <h3>{transaction.merchant}</h3>
          <Layout className="infoText">
            <span>{transaction.date.format('ll')}</span>
            <span className="sum">
              {transaction.sum} {transaction.currency}
            </span>
          </Layout>
        </div>
        <h3 className="mini-headers">
          {this.getEmailListHeaderText(transaction)}
        </h3>
      </Header>
    )
  }
}

EmailListHeader.propTypes = {
  onFreeTextEmailSearch: PropTypes.func,
  transaction: transactionPropType
}

export default EmailListHeader
