import React, { PureComponent } from 'react'
import { Button } from 'antd'
import { hasSelectedEmail } from '../../domain/transaction'

class HandleNonEmailReceipts extends PureComponent {
  render() {
    const {
      // Props:
      transaction,
      cancelKey,
      cancelAction,
      ignoreKey,
      ignoreAction
    } = this.props

    const hasSelectedReceipt = hasSelectedEmail(transaction)
    const readyForExport = transaction.readyForExport
    const ignored = readyForExport && !hasSelectedReceipt

    const getIgnoreButton = () => {
      if (ignored) {
        return (
          <Button
            key={'unignore--' + transaction.id}
            onClick={cancelAction}
            className="button button--text"
            icon="plus"
          >
            Unignore [{cancelKey}]
          </Button>
        )
      }

      if (readyForExport) {
        return null
      }

      return (
        <Button
          key={'ignore--' + transaction.id}
          onClick={ignoreAction}
          className="button button--text"
          icon="cross"
        >
          Ignore this transaction [{ignoreKey}]
        </Button>
      )
    }

    const actionButtons = getIgnoreButton() // TODO: Add more actions,such as uploading a file or taking a pic

    if (actionButtons) {
      return (
        <div>
          <h3 className="mini-headers">
            {ignored
              ? `Ignoring this transaction. It won't get exported.`
              : 'Receipt not availble by email?'}
          </h3>
          {actionButtons}
        </div>
      )
    }
    return null
  }
}

export default HandleNonEmailReceipts
