// User.js, database commands for user space
import * as firebase from 'firebase/app'
import generateId from '../util/generateId'
import moment from '../util/moment'

const uniqueId = accountInfo => {
  return accountInfo.number
    ? generateId([], `${accountInfo.number}`)
    : generateId([...accountInfo], `accountNumber-missing-`)
}

async function create(accountInfo, uid = firebase.auth().currentUser.uid) {
  // accountInfo = {name: String, accountNumber: String }}
  if (!accountInfo || !uid) return
  const accountId = accountInfo.id ? accountInfo.id : uniqueId(accountInfo)
  const updates = {}
  Object.keys(accountInfo).forEach(key => {
    updates[`/user/${uid}/accounts/${accountId}/${key}`] = accountInfo[key]
  })
  updates[`/user/${uid}/accounts/${accountId}/id`] = accountId

  return firebase
    .database()
    .ref()
    .update(updates)
}

async function update(
  accountId,
  accountInfo,
  uid = firebase.auth().currentUser.uid
) {
  // accountInfo = {name: String, meta: { seperateImports: boolean }}
  if (!accountId || !accountInfo || !uid) return
  const updates = {}
  Object.keys(accountInfo).forEach(key => {
    updates[`/user/${uid}/accounts/${accountId}/${key}`] = accountInfo[key]
  })
  return firebase
    .database()
    .ref()
    .update(updates)
}

async function del(accountId, uid = firebase.auth().currentUser.uid) {
  if (!accountId || !uid) return
  return Promise.all([
    firebase
      .database()
      .ref(`/user/${uid}/accounts/${accountId}`)
      .remove(),
    firebase
      .database()
      .ref(`/user/${uid}/transactionList/${accountId}`)
      .remove()
  ])
}

function parseAccountSnapshot(snapshot) {
  const accounts = snapshot.val()
    ? Object.values(snapshot.val()).map(account => ({
        ...account,
        startDate: account.startDate ? moment(account.startDate, 'x') : null,
        endDate: account.endDate ? moment(account.endDate, 'x') : null
      }))
    : []
  return accounts
}

async function listenAll(
  uid = firebase.auth().currentUser.uid,
  callback = () => null,
  firstLoadCallback = () => null
) {
  if (!uid) return
  const accountsListener = firebase.database().ref(`/user/${uid}/accounts/`)
  accountsListener.on('value', snapshot => {
    callback(parseAccountSnapshot(snapshot))
  })
  return accountsListener.once('value').then(snapshot => {
    firstLoadCallback(parseAccountSnapshot(snapshot))
  })
}

async function stopListenAll(uid = firebase.auth().currentUser.uid) {
  if (!uid) return
  firebase
    .database()
    .ref(`/user/${uid}/accounts/`)
    .off()
}

async function listen(
  accountId,
  uid = firebase.auth().currentUser.uid,
  callback = input => null
) {
  if (!uid) return
  const accountListener = firebase
    .database()
    .ref(`/user/${uid}/accounts/${accountId}`)
  accountListener.on('value', snapshot => {
    const account = {
      ...snapshot.val(),
      startDate: snapshot.val().startDate
        ? moment(snapshot.val().startDate, 'x')
        : false,
      endDate: snapshot.val().endDate
        ? moment(snapshot.val().endDate, 'x')
        : false
    }
    callback(account)
  })
  return accountListener.once('value')
}

async function stopListen(accountId, uid = firebase.auth().currentUser.uid) {
  if (!uid || !accountId) return
  return firebase
    .database()
    .ref(`/user/${uid}/accounts/${accountId}`)
    .off()
}

const account = {
  create,
  update,
  del,
  listenAll,
  listen,
  stopListenAll,
  stopListen
}

export default account
